import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Input, Card, CardBody, Button, Label, Tooltip } from 'reactstrap';
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { toast } from 'react-toastify';

import { api } from "../../components/tools";

const Direction = ({ CloseForm, dataAddress, action }) => {
	const [userId, setUserID] = useState(null)
	const [direction, setDirection] = useState({
		comentario: (!dataAddress.comentario ? "" : dataAddress.comentario),
		address: (!dataAddress.address ? "" : dataAddress.address),
		country: (!dataAddress.country ? "" : dataAddress.country),
		state: (!dataAddress.state ? "" : dataAddress.state),
		city: (!dataAddress.city ? "" : dataAddress.city),
		name: (!dataAddress.name ? "" : dataAddress.name),
		zip: (!dataAddress.zip ? "" : dataAddress.zip),
		id: (!dataAddress.id ? "" : dataAddress.id),
		customer_id: null,
		status: true,
		deleted: 0,
	});
	const [address, setAddress] = React.useState("");

	const handleInputChange = (event) => {
		setDirection({
			...direction,
			[event.target.name]: event.target.value
		})
	}

	const saveAddress = (event) => {
		event.preventDefault();

		// Get API addresses
		const type = (action === "new") ? 'post' : 'put'
		api({
			...direction,
			url: 'address',
			type: type
		}).then((resp) => {
			console.log('saveAddress', resp);

			if (!resp.data) {
				toast.error("Error al guardar la dirección");

				return;
			}
			if (resp.data.error) {
				toast.error("Error al guardar la dirección");

				return;
			}

			// All ok
			toast.success("Dirección guardada con éxito");
			localStorage.removeItem("_addresses")
			CloseForm();

			// window.location.reload(false);
		}).catch((err) => {
			console.log('err', err);

			toast.error("Error al guardar la dirección");
		})
	}

	/* Observers
	======================================== */
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		if (!userId) {
			let user = localStorage.getItem("_client")
			if (user) {
				user = JSON.parse(user)

				if (user.person_id) {
					setUserID(user.person_id)

					setDirection({
						...direction,
						customer_id: user.person_id
					})
				}
			}
		}
	}, [userId]);

	const handleSelect = async value => {
		const results = await geocodeByAddress(value);
		const directionObj = results[0].address_components;
		let route = "";
		let street_number = "";
		let neighborhood = "";
		let locality = "";
		let state = "";
		let country = "";
		let postal_code = "";

		for (let index = 0; index < directionObj.length; index++) {
			const element = directionObj[index];
			if (element.types[0] === "route") {
				route = element.long_name;
			} else if (element.types[0] === "street_number") {
				street_number = element.long_name;
			} else if (element.types[2]) {
				neighborhood = element.long_name
			} else if (element.types[0] === "locality") {
				locality = element.long_name;
			} else if (element.types[0] === "administrative_area_level_1") {
				state = element.long_name;
			} else if (element.types[0] === "country") {
				country = element.long_name;
			} else if (element.types[0] === "postal_code") {
				postal_code = element.long_name;
			}
		}

		console.log('directionObj', directionObj);
		

		const address = route+" "+street_number+", "+neighborhood
		setDirection({
			...direction,
			zip: postal_code !== "" ? postal_code : direction.postal_code,
			country: country !== "" ? country : direction.country,
			city: locality !== "" ? locality : direction.town,
			state: state !== "" ? state : direction.state,
			address: address,
		});
		setAddress(value);
	};

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const toggle = () => setTooltipOpen(!tooltipOpen);

	return (
		<>
			{/*Address section*/}
			<Container>
				<Row>
					<Col lg={12} md={12} className="ml-auto mr-auto">
						<Card>
							<CardBody>
								<div className="register-form">
									<form id="contact-form" onSubmit={saveAddress}>
										<input id="form_id" type="hidden" name="address" value={direction.id} />
										<div className="messages" />
										<Row>
											<Col lg={12}>
												<Label for="">
													Buscar de dirección
													<span style={{ textDecoration: "underline", color: "blue" }} href="#" id="DisabledAutoHideMapsInstruction">
														<i className="las la-question-circle"></i>
													</span>
												</Label>
												<Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="DisabledAutoHideMapsInstruction" toggle={toggle}>
													Selecciona una dirección de las sugerencias
												</Tooltip>
												<PlacesAutocomplete
													onSelect={handleSelect}
													onChange={setAddress}
													value={address}
												>
													{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
														<div>
															<input {...getInputProps({ placeholder: "Buscar dirección " })} className="form-control" />
															<br></br>
															<div>
																{loading ? <div>...loading</div> : null}

																{suggestions.map((suggestion, i) => {
																	const style = {
																		backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
																	};

																	return (
																		<div {...getSuggestionItemProps(suggestion, { style })} key={i}>
																			{suggestion.description}
																		</div>
																	);
																})}
															</div>
														</div>
													)}
												</PlacesAutocomplete>
											</Col>
										</Row>
										<Row>
											<Col md={12}>
												<div className="form-group">
													<Label for="form_address">Dirección</Label>
													<input id="form_address" type="text" name="address" value={direction.address} className="form-control" placeholder="Ejem: Calz. Lázaro Cárdenas 2305, Las Torres" required="required" data-error="Calle es requerida." onChange={handleInputChange} />
													<div className="help-block with-errors" />
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={4}>
												<div className="form-group">
													<Label for="form_zip">Código postal</Label>
													<input id="form_zip" type="text" name="zip" value={direction.zip} className="form-control" placeholder="Ejem: 45300" required="required" data-error="Código postal es requerido." onChange={handleInputChange} maxLength="5" minLength="5" />
													<div className="help-block with-errors" />
												</div>
											</Col>
											<Col md={4}>
												<div className="form-group">
													<Label for="form_city">Ciudad</Label>
													<input id="form_city" type="text" name="city" value={direction.city} className="form-control" placeholder="Ejem: ciudad de México" required="required" data-error="Ciudad es requerida." onChange={handleInputChange} />
													<div className="help-block with-errors" />
												</div>
											</Col>
											<Col md={4}>
												<div className="form-group">
													<Label for="form_state">Estado</Label>
													<input id="form_state" type="text" name="state" value={direction.state} className="form-control" placeholder="Ejem: Jalisco" required="required" data-error="Estado es requirido." onChange={handleInputChange} />
													<div className="help-block with-errors" />
												</div>
											</Col>
											<Col md={4}>
												<div className="form-group"  >
													<Label for="country">País</Label>
													<select className="form-control" name="country" value={direction.country} onChange={handleInputChange} required="required" data-error="El pais es requerido.">
														<option value="">Elige uno</option>
														<option value="Mexico">Mexico</option>
														<option value="Argentina">Argentina</option>
														<option value="China">China</option>
														<option value="Estados Unidos">Estados Unidos</option>
														<option value="Guatemala">Guatemala</option>
													</select>
													<div className="help-block with-errors" />
												</div>
											</Col>
											<Col md={4}>
												<div className="form-group">
													<Label for="form_name_direction">Nombre de dirección</Label>
													<input 
														data-error="Nombre de dirección es requerida." onChange={handleInputChange} 
														placeholder="Casa, trabajo, etc." 
														className="form-control" 
														id="form_name_direction" 
														value={direction.name} 
														required="required" 
														name="name" 
														type="text" 
													/>
													<div className="help-block with-errors" />
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={12}>
												<Label for="form_comentario">Comentarios</Label>
												<Input type="textarea" name="comentario" value={direction.comentario} id="form_comentario" onChange={handleInputChange} />
											</Col>
										</Row>
										<Row style={{paddingTop: 15}}>
											<Col md={12}>
												<Button type="submit" className='btn btn-primary' style={{ marginRight: "10px", width: "100px" }}>Guardar</Button>
												<Button type="button" className='btn btn-default' style={{ width: "100px" }} onClick={() => CloseForm()}>Cancelar </Button>
											</Col>
										</Row>
									</form>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
			{/*body content end*/}
		</>
	);
}

export default React.memo(Direction);