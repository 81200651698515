import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from 'reactstrap';

import { lan } from '../../components/tools';

const CheckoutForm = () => {
	const stripe = useStripe()
	const elements = useElements()

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) return;

		const result = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: document.location.origin+'/stripe/complete',
			},
		})

		// Show error to your customer (for example, payment details incomplete)
		if (result.error) console.log(result.error.message)
	}

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			<br />
			<Button className="btn btn-primary mr-3" block>{lan('pay')}</Button>
		</form>
	)
};

export default CheckoutForm;