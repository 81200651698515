import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'reactstrap'
import Moment from 'react-moment';

import { api, getClient, NoData, getCurrency } from "../../components/tools";

const ListSales = () => {
	let history = useNavigate();

	const [customerSales, setCustomerSales] = useState(false)

	const getSales = useCallback(() => {
		// Get client data
		const user = getClient()
		if (!user) throw new Error("No client detected")

		// Get local sales
		let local_sales = localStorage.getItem("_customerSales");
		if (local_sales) {
			const sales = JSON.parse(local_sales)

			setCustomerSales(sales)

			return
		}

		// Get sales from API
		api({
			customer_id: user.person_id,
			url: 'sales'
		}).then((resp) => {
			console.log('getSales', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get sales: " + resp.data.error)
				}

				if (resp.data.length <= 0) {
					setCustomerSales([])
					return
				}

				// JSON to array
				let result = [];
				for (let i in resp.data) result.push(resp.data[i])

				// Set sales
				setCustomerSales(result)
				localStorage.setItem("_customerSales", JSON.stringify(result));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get sales: " + err)
		})
	}, [setCustomerSales])

	useEffect(() => {
		if (!customerSales) getSales()
	}, [customerSales, getSales])

	const SaleDetails = (sale) => {
		console.log('SaleDetails', sale);

		localStorage.setItem("_sale", JSON.stringify(sale));

		history("/sale")
	}

	return (<>
		{!customerSales ? <NoData /> :
			<Table hover striped responsive>
				<thead>
					<tr>
						<th>#</th>
						<th>Total</th>
						<th>Productos</th>
						<th>Fecha de compra</th>
					</tr>
				</thead>
				<tbody>
					{customerSales.map((sale, i) => (
						<tr key={i} onClick={() => SaleDetails(sale)} className='pointer'>
							<td>
								{sale.sale_id}
							</td>
							<td>{getCurrency()}{parseFloat(sale.total)}</td>
							<td>
								{parseInt(sale.total_quantity_purchased)} - 
								{(' ' + sale.products.map((product) => product.name)).substring(0, 70)}
							</td>
							<td>
								<Moment format='DD MMM YYYY H:m'>
									{sale.sale_time}
								</Moment>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		}
	</>)
}
export default ListSales;
