import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

import { api, lan, Message } from "../../components/tools";

const SignUp = () => {
	const history = useNavigate()

	/* Hooks
	======================================== */
	const [disabled, setDisabled] = useState(false)
	const [message, setMessage] = useState(false)
	const [dataMessage, setDataMessage] = useState({
		message: 'Ocurrió un error',
		title: 'Error',
	})
	const [data, setData] = useState({
		phone_number: '',
		first_name: '',
		user_pass2: '',
		user_pass: '',
		last_name: '',
		country: 'MX',
		email: '',
	})


	/* Functions
	======================================== */
	// Function that ejecute when the user write an input
	const updateData = e => {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	/* Check if user exists */
	const userExists = (e) => {
		e.preventDefault()

		// Hide message
		setMessage(false)

		// Loading
		setDisabled(true)

		api({
			or_tel: data.phone_number,
			mail: data.email,
			url: 'clients',
		}).then((res) => {
			// Loading	
			setDisabled(false)

			if (res.data.length > 0) {
				setDataMessage({
					message: lan('user_registered'),
					title: lan('error'),
				})
				setMessage(true)

				return;
			}

			// Create customer
			createCustomer()
		}).catch((err) => {
			// Loading
			setDisabled(false)

			// Show error
			setDataMessage({
				message: 'Algo salio mal, intenta mas tarde',
				title: 'Error',
			})
			setMessage(true)
		})
	}

	// Create customers on API
	const createCustomer = () => {
		// Validations
		if (data.user_pass !== data.user_pass2) {
			setDataMessage({
				message: lan('pass_no_match'),
				title: lan('pass_no_match'),
			})
			setMessage(true)

			return;
		}

		// Loading
		setDisabled(true)

		api({
			url: 'clients',
			type: 'post',
			...data,
		}).then((res) => {
			// Loading
			setDisabled(false)

			// Validations
			if (!res.data || res.data.error){
				setDataMessage({
					message: lan('user_registered'),
					title: lan('error'),
				})
				setMessage(true)
				toast.error(lan('user_registered'))

				throw new Error("Error al crear el cliente: ")
			}

			// Show alert
			setDataMessage({
				message: lan('user_create_message'),
				title: lan('user_create'),
			})
			setMessage(true)
			toast.success(lan('user_create_message'))
			toast.success(lan('user_create'))

			// Clean form
			setData({
				phone_number: '',
				first_name: '',
				user_pass2: '',
				user_pass: '',
				last_name: '',
				country: 'MX',
				email: '',
			})

			// Go to login
			setTimeout(() => {
				history("/login")
			}, 3000)
		}).catch((err) => {
			// Loading
			setDisabled(false)

			// Show error
			const message = err.message ?? lan('error')
			setDataMessage({
				message: message,
				title: 'Error',
			})
			setMessage(true)
		})
	}

	return (
		<>
			<HeaderPage />
			<div className="page-content">
				<section className="register">
					<Container>
						{!message ? <></> : 
							<Row>
								<Col lg={8} md={10} className="ml-auto mr-auto">
									<Message 
										message={dataMessage.message}
										title={dataMessage.title}
									/>
								</Col>
							</Row>
						}
						<Row>
							<Col lg={8} md={10} className="ml-auto mr-auto">
								<div className="register-form">
									<form id="contact-form" method="post" onSubmit={userExists}>
										<Row>
											<Col md={6}>
												<div className="form-group">
													<input
														data-error="El nombre es requerido."
														className="form-control"
														placeholder="Nombre *"
														required="required"
														name="first_name"
														type="text"

														onChange={updateData}
														value={data.first_name}
													/>
													<div className="help-block with-errors" />
												</div>
											</Col>
											<Col md={6}>
												<div className="form-group">
													<input
														data-error="El apellido es requerido."
														placeholder="Apellidos *"
														className="form-control"
														required="required"
														name="last_name"
														type="text"

														onChange={updateData}
														value={data.last_name}
													/>
													<div className="help-block with-errors" />
												</div>
											</Col>
											<Col md={6}>
												<div className="form-group">
													<input
														data-error="El correo es requerido."
														placeholder="alguien@ecommpos.com  *"
														className="form-control"
														required="required"
														type="email"
														name="email"

														onChange={updateData}
														value={data.email}
													/>
													<div className="help-block with-errors" />
												</div>
											</Col>
											<Col md={6}>
												<div className="form-group">
													<input
														data-error="El teléfono es requerido"
														className="form-control"
														placeholder="0123456789  *"
														required="required"
														name="phone_number"
														type="tel"

														value={data.phone_number}
														onChange={updateData}
													/>
												</div>
											</Col>
											<Col md={6}>
												<div className="form-group">
													<input
														type="password"
														name="user_pass"
														className="form-control"
														placeholder="Contraseña  *"
														required="required"
														data-error="La contraseña es requerida."

														onChange={updateData}
														value={data.user_pass}
													/>
												</div>
											</Col>
											<Col md={6}>
												<div className="form-group">
													<input
														type="password"
														name="user_pass2"
														className="form-control"
														placeholder="Repetir contraseña  *"
														required="required"
														data-error="La contraseña es requerida."

														onChange={updateData}
														value={data.user_pass2}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col md={12}>
												<button type="submit" className="btn btn-primary btn-block" disabled={disabled}>
													Crear cuenta
												</button>
											</Col>
										</Row>
										<Row className='text-center'>
											<Col md="12">
												<span className="mt-4 d-block">
													¿Ya tienes cuenta? <Link to="/login">Inicia sesión</Link>
												</span>
											</Col>
										</Row>
									</form>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default SignUp;