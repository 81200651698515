import { toast } from 'react-toastify';
import axios from 'axios';

import mx from '../languages/mx.json'
import en from '../languages/en.json'
import ch from '../languages/ch.json'

// import config from '../env_local.json'
import config from '../env.json'

const url = config.URL_API

export const getClient = () =>{
	const json_client = localStorage.getItem("_client");
	if (!json_client) return false

	return JSON.parse(json_client)
}

export const api = async (data) => {
	const token = localStorage.getItem("_token");

	try {
		// Validations
		if (!data.url) throw new Error("url required")
		if (!data.type) data.type = 'get'

		// Add security data
		data.client = getClient()
		data.token = token

		// For client validation on API
		if (data.client) {
			if (!data.client.tel && data.client.phone_number) {
				data.client.tel = data.client.phone_number
			}
		}

		// Build request
		const type = data.type ? data.type : 'get'
		const _url = url+data.url
		delete data.type
		delete data.url

		// Build request
		let request = {
			method: type,
			url: _url,
			headers: {
				'Content-Type': 'application/json',
			}
		}
		if (type === 'get') {
			request.params = data
		}else{	
			request.data = data
		}

		// Call API
		const res = await axios(request)

		// Error
		if (res.status !== 200) {
			console.error(res);
			throw new Error("Error api")
		}

		// Resp
		return res
	}catch (err) {
		console.error(err);

		let message = "Error api"
		if (err.response) {
			if (err.response.data) {
				if (err.response.data.message) {
					message = err.response.data.message
				}else{
					if (typeof err.response.data === "string") {
						message = err.response.data
					}
				}
			}
		}
		
		toast.error(message)
		throw new Error(message)
	}
}

export const NoData = (props) => {
    const text = props.text || 'No encontramos información relacionada. Prueba una búsqueda diferente';
    const title = props.title || 'Sin datos';

    return (
        <div className="text-center col-12">
            <div className="shadow-sm rounded p-5">
                <div className="mb-5">
                    <h4 className="text-primary mb-1">{title}</h4>
                    <h6 className="mb-0">{text}</h6>
                </div>
            </div>
        </div>
    );
}

export const Message = (data) => {
    const message = data.message ?? 'Ocurrió un error inesperado';
    const title = data.title ?? 'Error';

    return (
        <div className="text-center col-12">
            <div className="shadow-sm rounded p-5">
                <div className="mb-5">
                    <h4 className="text-primary mb-1">{title}</h4>
                    <h6 className="mb-0">{message}</h6>
                </div>
            </div>
        </div>
    );
}

export const Loading = () =>{
	return(
        <div style={{textAlign: 'center'}}>
            <img className="img-fluid" src={require(`../assets/images/gifloadercomponent.gif`).default} alt="" />
        </div>
    )
}

export const change_lan = (lan) => {
	localStorage.setItem("_language", lan)

	window.location.reload()
}

export const lan = (word) => {
	// Get language
	let language = localStorage.getItem("_language");
	if (!language){
		localStorage.setItem("_language", "mx");
		language = 'mx'
	}

	// Get translations
	let resp = ' - '
	switch (language) {
		case 'mx':
			if(mx[word]) resp = mx[word]
			break;
		case 'en':
			if(en[word]) resp = en[word]
			break;
		case 'ch':
			if(ch[word]) resp = ch[word]
			break;
	
		default:
			if(mx[word]) resp = mx[word]
			break;
	}

	return resp
}

export const getCurrency = () =>{
	let currency = "$"

	// Get store config
	let store_config = localStorage.getItem("_config")
	if (!store_config) return currency

	// Get currency symbol from config
	store_config = JSON.parse(store_config)
	if (store_config.currency_symbol) currency = store_config.currency_symbol

	return currency
}

export const getConfig = () =>{
	let store_config = localStorage.getItem("_config")
	if (!store_config) return false

	store_config = JSON.parse(store_config)

	return store_config
}
