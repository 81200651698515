import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Col,
	Container,
	Row,
	Collapse,
	Navbar,
	NavbarToggler,
	Nav,
	NavItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	Modal,
	ModalHeader,
	ModalBody,
	ListGroup,
	ListGroupItem
} from 'reactstrap'

import { ToastContainer } from 'react-toastify';

import { api, NoData, getCurrency } from "../../components/tools";

import './Header.css'

const logo = require(`../../assets/images/default-image.png`).default

function HeaderPage({ keyPage }) {
	const [categories, setCategories] = useState(false)
	const [scrolled, setScrolled] = useState()
	const [config, setConfig] = useState(null)
	const [items, setItems] = useState([])
	const [flags, setFlags] = useState({
		cartview: false,
		isOpen: false,
		loader: true
	})

	const getCategories = () => {
		// Get local categories
		let local_categories = localStorage.getItem("_categories");
		if (local_categories) {
			const _categories = JSON.parse(local_categories)

			setCategories(_categories)

			return _categories
		}

		// Get API categories
		api({
			url: 'categories',
		}).then((resp) => {
			console.log('categories', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get categories: " + resp.data.error)
				}

				// JSON to array
				let result = [];
				for (var i in resp.data) result.push(resp.data[i])

				// Set categories
				setCategories(result)

				// Save categories on local
				localStorage.setItem("_categories", JSON.stringify(result));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get categories: " + err)
		})
	}

	const GetCartItems = () => {
		const cart = JSON.parse(localStorage.getItem("CartProduct"))
		if (cart && cart.length > 0) setItems(cart)

		return cart;
	}

	const RemoveItem = (Index) => {
		var CartValue = JSON.parse(localStorage.getItem("CartProduct"));
		CartValue = CartValue.slice(0, Index).concat(CartValue.slice(Index + 1, CartValue.length));
		localStorage.removeItem("CartProduct");
		localStorage.setItem("CartProduct", JSON.stringify(CartValue));

		// Update hooks
		setItems(CartValue)
	}

	const CartView = () => {
		//the value of cartview is updated
		setFlags({ ...flags, cartview: !flags.cartview });

		// Get cart items
		GetCartItems()
	}

	const toggle = () => {
		//the value of isOpen is updated
		setFlags({ ...flags, isOpen: !flags.isOpen });
	}

	const handleClick = (event) => {
		var elems = document.querySelectorAll(".childsubmenu");
		[].forEach.call(elems, function (el) {
			el.classList.remove("show");
		});
		showGif();
	}

	const showGif = () => {
		setFlags({ ...flags, loader: true });
		toggle();
	}

	const AllCategories = () => {
		let options = [
			<li key={`category_all` + keyPage}>
				<Link key={`linkcat_all` + keyPage} to={`/category/todos/todas`} onClick={handleClick}>
					Todas
				</Link>
			</li>
		];

		return (
			<ul className="list-unstyled">
				{/* Build categories HTML */}
				{categories.map((category, i) => {
					// Second level
					let _options = []
					if (category.categories) {
						_options = []
						for (var ii in category.categories) {
							let _category = category.categories[ii]

							//  Third level
							let options_third = []
							if (_category.categories) {
								options_third = []
								for (var iii in _category.categories) {
									let category_third = _category.categories[iii]

									options_third.push(
										<li style={{ marginLeft: '1rem' }} key={`category_third_${iii}` + keyPage}>
											<Link key={`link_cat_${iii}` + keyPage} to={`/category/${category_third.name}/${category_third.id}`} onClick={handleClick}>
												{"      "}{category_third.name}
											</Link>
										</li>
									)
								}
							}

							const third_level = (options_third.length > 0) ? <ul style={{ listStyleType: 'circle' }}>{options_third}</ul> : <></>

							_options.push(
								<li style={{ marginLeft: '.5rem' }} key={`category_second_${ii}` + keyPage}>
									<Link key={`link_cat_${ii}` + keyPage} to={`/category/${_category.name}/${_category.id}`} onClick={handleClick}>
										{"\t"}{_category.name}
									</Link>
									{third_level}
								</li>
							)
						}
					}

					const second_level = (_options.length > 0) ? <ul>{_options}</ul> : <></>

					// First level
					options.push(
						<li key={`category_${i}` + keyPage}>
							<Link key={`linkcat_${i}` + keyPage} to={`/category/${category.name}/${category.id}`} onClick={handleClick}>
								{category.name}
							</Link>
							{second_level}
						</li>
					)

					return false
				})}

				{options}{/* Print categories*/}
			</ul>
		);
	}
	
	const imageError = (currentTarget, product) =>{
		if (product.images) {
			if (product.images.length > 0) {
				currentTarget.src = product.images[0]
			}
		}else{
			currentTarget.src = logo
		}
	}

	const getConfig = () => {
		// Get local config
		let local_config = localStorage.getItem("_config");
		if (local_config) {
			const _config = JSON.parse(local_config)

			setConfig(_config)

			return _config
		}

		// Get API config
		api({
			url: 'config',
		}).then((resp) => {
			console.log('config', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get config: " + resp.data.error)
				}

				// Set config
				setConfig(resp.data)

				// Save config on local
				localStorage.setItem("_config", JSON.stringify(resp.data));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get config: " + err)
		})
	}

	const calculateTotal = () =>{
		if (items.length < 1) return 0

		let total_items = 0
		for (const key in items) {
			if (Object.prototype.hasOwnProperty.call(items, key)) {
				const item = items[key]

				total_items += item.Qty
			}
		}

		return total_items
	}
	

	/* Observers
	============================================ */

	//place static header when scroll is scrolled down
	useEffect(() => {
		// Get categories
		if (!categories) getCategories()

		// Get config
		if (!config) getConfig()

		// Get cart items
		GetCartItems()

		// Scroll up
		const handleScroll = () => {
			if (window.pageYOffset > 1) {
				setScrolled(true)
			} else {
				setScrolled(false)
			}
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
		<>
			<header className="site-header">
				<ToastContainer autoClose={900} />
				<div id="header-wrap" className={`${(scrolled) ? "shadow-sm " : "shadow-sm"}`} >
					<Container>
						<Row>
							<Col>
								<Navbar className="navbar-expand-lg navbar-light position-static">
									<NavbarToggler key={"obilMenu" + keyPage} onClick={() => toggle()} />
									<Collapse isOpen={flags.isOpen} className="navbar-collapse" navbar>
										<Nav className="navbar-nav" navbar>
											<NavItem key={"Home_header" + keyPage} onClick={() => showGif()}>
												<Link to="/" className="nav-link">INICIO</Link>
											</NavItem>
											<UncontrolledDropdown nav inNavbar key={"categoriesHeader" + keyPage} >
												<DropdownToggle nav caret >
													CATEGORÍAS
												</DropdownToggle>
												<DropdownMenu id={`submenu_1`} className="childsubmenu">
													<Row className="w-100 no-gutters">
														<div className="container p-0">
															{!categories ? <NoData /> : <AllCategories />}
														</div>
													</Row>
												</DropdownMenu>
											</UncontrolledDropdown>
											<NavItem key={"Contact_header" + keyPage} onClick={() => showGif()}>
												<Link to="/contact" className="nav-link">CONTACTO</Link>
											</NavItem>
											<NavItem key={"AboutHeader" + keyPage} onClick={() => showGif()}>
												<Link to="/about" className="nav-link">ACERCA DE NOSOTROS</Link>
											</NavItem>
										</Nav>
									</Collapse>
									<div className="right-nav align-items-center d-flex justify-content-end">
										<Link key={"userInfoHeader" + keyPage} className="mr-1 mr-sm-3" to="/customer">
											<i className="las la-user-alt" />
										</Link>
										<Link key={keyPage + "user_data_shop"} className="d-flex align-items-center" to="#" id="header-cart-btn" onClick={() => CartView()} >
											<span className="px-2 py-1 rounded">
												<i className="las la-shopping-cart" /> 
												<div className="count-cart">
													<span id='total_items'>{calculateTotal()}</span>
												</div>
											</span>
										</Link>
									</div>
								</Navbar>
							</Col>
						</Row>
					</Container>
				</div>
			</header>
			<Modal isOpen={flags.cartview} toggle={CartView} className="cart-modal" >
				<ModalHeader toggle={() => CartView()}>
					Su carrito ({items ? items.length : 0})
				</ModalHeader>
				<ModalBody >
					{(items.length > 0) ?
						<ListGroup>
							{items.map((CartItem, index) => {
								let name = CartItem.name

								// EC-961 Variation name
								if (CartItem.variation_name) name = CartItem.name+' - '+CartItem.variation_name
								
								return (<ListGroupItem key={index} >
									<div>
										<div className="row align-items-center" >
											<div className="col-5 d-flex align-items-center" >
												<div className="mr-4" >
													<Link to="#" type="submit" className="btn btn-primary btn-sm" onClick={() => RemoveItem(index)}>
														<i className="las la-times" key={"times" + index} />
													</Link>
												</div>
												<a href="#" >
													<img 
														style={{ height: '60px', width: '60px' }} 
														src={`${CartItem.image}`} 
														className="img-fluid" 
														alt="..." 

														onError={({ currentTarget }) => {
															imageError(currentTarget, CartItem)
														}}
													/>
												</a>
											</div>
											<div className="col-7" key={"test32_" + index + keyPage} style={{verticalAlign: 'middle'}}>
												<h6 >
													<a className="link-title" href="#" key={"ker_yer_" + index + keyPage}>{name}</a>
												</h6>
												<div className="product-meta" key={"product_data" + index + keyPage}>
													<span className="mr-2 text-primary" >
														{getCurrency()}{(parseFloat(CartItem.price)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
													</span>
													<span >x {CartItem.Qty}</span>
												</div>
											</div>
										</div>
									</div>
									<hr className="my-5" key={"hrcar_" + keyPage + index} />
								</ListGroupItem>
								)
							})}
							<br />
							<div className="d-flex justify-content-between align-items-center mb-8">
								<span>Subtotal:</span>
								<span>
									{getCurrency()}{
										items
											.reduce((fr, CartItem) => fr + (CartItem.Qty * parseFloat(CartItem.price)), 0)
											.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
									}
								</span>
							</div>
							<Link to="/cart" className="btn btn-primary btn-animated mr-2" onClick={() => CartView()}>
								<i className="las la-shopping-cart mr-1" />Ver carrito
							</Link><br />
							<Link to="/direction?checkout=1" className="btn btn-primary btn-animated mr-2" onClick={() => CartView()}>
								<i className="las la-money-check mr-1" />Terminar compra
							</Link>
						</ListGroup>
						:
						<div key={keyPage + "no_data_cart"}>
							<div className="row align-items-center">
								<h3 className="mb-4">Su carrito está vacío.</h3>
							</div>
						</div>
					}
				</ModalBody>
			</Modal>
		</>
	);
}

export default HeaderPage;