import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap';

import { api, getCurrency, lan } from "../../components/tools";

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

const StripeComplete = () => {
	const history = useNavigate()
	
	/* Hooks
	======================================== */
	const [params, setParams] = useSearchParams()

	// Get data
	const payment_intent_client_secret = params.get("payment_intent_client_secret")
	const payment_intent = params.get("payment_intent")
	const status = params.get("redirect_status")
	let message = lan('order_complete_message')

	/* Functions
	======================================== */
	const GetCartItems = () => {
		return JSON.parse(localStorage.getItem("CartProduct"));
	}

	const completeSale = () => {
		let date = new Date()
		date.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

		// Init vars
		const deliveryDate = JSON.parse(localStorage.getItem("deliveryDate"))
		const address = JSON.parse(localStorage.getItem("_address"))
		const user = JSON.parse(localStorage.getItem("_client"))

		// Validations
		if (!user) {
			console.error('No user')

			return;
		}

		// Get current date
		let delivery_date = new Date(deliveryDate);
		let dd = String(delivery_date.getDate()).padStart(2, '0');
		let mm = String(delivery_date.getMonth() + 1).padStart(2, '0');
		let yyyy = delivery_date.getFullYear();
		delivery_date = dd + '-' + mm + '-' + yyyy;

		// Calculate total
		const total = GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * parseFloat(CartItem.price)), 0)

		// Sale data
		let data = {
			payment_type: "Tarjeta: " + getCurrency() + total + "<br />",
			comment: lan('sale_from_ecommerce'),
			delivery_date: delivery_date,
			from: "Ecommerce",
			address: address,
			payment: 'card',
			products: [],
			payment_data: {
				payment_intent_client_secret: payment_intent_client_secret,
				payment_intent: payment_intent,
				status: status,
			}
		}

		// Get products
		const products = GetCartItems()
		products.map((value) => {
			data.products.push({
				cost_price: parseFloat(value.cost_price),
				price: parseFloat(value.price),
				quantity: value.Qty,
				id: value.item_id,
				name: value.name,
				tax: 0,
			})

			return value
		})

		// New sale on API
		api({
			...data,
			url: 'newSale',
			type: 'post',
		}).then((resp) => {
			console.log('resp', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					console.log('error');

					return
				}

				// Clean data
				localStorage.removeItem("_productsMostSold")
				localStorage.removeItem("_productsRating")
				localStorage.removeItem("product_details")
				localStorage.removeItem('_customerSales')
				localStorage.removeItem("_productsNew")
				localStorage.removeItem("_allProducts")
				localStorage.removeItem("CartProduct")
				localStorage.removeItem('_address');

				// Redirect
				history("/orderComplete")
			}
		}).catch((err) => {
			console.log('err', err);
		})
	}

	// Validations
	if (status === 'succeeded'){ 
		completeSale()
	}else{
		message = lan('error_msn')
	}

	return (<div className="page-content">
		<HeaderPage />
		<section>
			<Container>
				<h5>{message}</h5>
			</Container>
		</section>
		<Footer />
	</div>);
}
 
export default StripeComplete;