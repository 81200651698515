import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import HeroSection from '../../widgets/heroSection/HeroSection'
import ProductsOffers from '../../widgets/home1/sales'
import NewProducts from '../../widgets/home1/newProduct'
import TheMostSold from '../../widgets/home1/theBestSellingProducts'
import RatingProducts from '../../widgets/home1/rating'

import HeaderPage from '../../layout/header/headerPage';
import Search from '../../layout/header/search';
import Footer from '../../layout/footer/footer';

function Home() {
	return (
		<>
			<HeaderPage keyPage={"HomePage"} />
			<HeroSection />
			<Search />
			<section>
				<div className="container-fluid px-lg-8">
					<Row className="justify-content-center text-left">
						<Col xs={12}>
							<div className="mb-8">
								<h6 className="text-primary mb-1" style={{ fontWeight: "bold" }}>Productos top</h6>
							</div>
						</Col>
					</Row>
					<Row>
						<RatingProducts />
					</Row>
				</div>
			</section>
			<section className="pt-0">
				<Container>
					<Row>
						<Col lg={12} md={12}>
							<div className="shadow p-5 rounded">
								<Row className="justify-content-center text-left mb-5">
									<Col xs={12}>
										<div>
											<h6 className="text-primary mb-1" style={{ fontWeight: "bold" }}>Nuevos productos</h6>
										</div>
									</Col>
								</Row>
								<NewProducts />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<div className="container-fluid px-lg-8">
					<Row className="justify-content-center text-left">
						<Col xs={12}>
							<div className="mb-8">
								<h6  style={{ fontWeight: "bold" }} className="text-primary mb-1">Ofertas</h6>
							</div>
						</Col>
					</Row>
					<Row>
						<ProductsOffers />
					</Row>
				</div>
			</section>
			<section>
				<div className="container-fluid px-lg-8">
					<Row className="justify-content-center text-left">
						<Col xs={12}>
							<div className="mb-8">
								<h6 className="text-primary mb-1" style={{ fontWeight: "bold" }}>Los más vendidos</h6>
							</div>
						</Col>
					</Row>
					<Row>
						<TheMostSold />
					</Row>
				</div>
			</section>
        	<Footer />
		</>
	)
}

export default React.memo(Home)
