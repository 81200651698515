import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardTitle, Col, Row, Container } from 'reactstrap';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

import { api, NoData } from "../../components/tools";

const About = () => {
	/* Hooks
	======================================== */
	const [config, setConfig] = useState(false)

	const getConfig = () => {
		// Get local config
		let local_config = localStorage.getItem("_config");
		if (local_config) {
			const _config = JSON.parse(local_config)

			setConfig(_config)

			return _config
		}

		// Get API config
		api({
			url: 'config',
		}).then((resp) => {
			console.log('config', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get config: " + resp.data.error)
				}

				// Set config
				setConfig(resp.data)

				// Save config on local
				localStorage.setItem("_config", JSON.stringify(resp.data));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get config: " + err)
		})
	}

	/* Observers
	======================================== */
	useEffect(() => {
		window.scrollTo(0, 0)

		if (!config) getConfig()
	}, [config]);

	// Policies
	let policies = false
	if (config.politica_envio) {
		policies = config.politica_envio.split('##')
	}

	return (<>
		<HeaderPage />
		<div className="page-content">
			<section>
				{!config ? <NoData /> :
					<Container>
						{!config.text_acerca_nosotros ? <></>:
							<Row className="mb-5">
								<Col>
									<Card>
										<CardBody>
											<CardTitle tag="h5">Acerca de nosotros</CardTitle> 
											<div style={{overflow:"auto", resize:"none", opacity: 1}} >
												{config.text_acerca_nosotros}
											</div>
										</CardBody>
									</Card>
								</Col>
							</Row>
						}
						{!config.img1_acerca_nosotros && !config.img2_acerca_nosotros && !config.descripcion_img1 && !config.descripcion_img2? <></>:
							<Row className="mb-5">
								<Col md={6} lg={6}>
									<div className="card product-card">
										<div className="card-img-hover d-block" style={{aspectRatio: '16 / 9'}}>
											<img className="card-img-top" style={{margin: 0}} src={config.img1_acerca_nosotros} width="190" alt='' />
										</div>
										<div className="card-info">
											<div className="card-body">
												<div className="product-title">
													{config.descripcion_img1}
												</div>
											</div>
										</div>
									</div>
								</Col>
								<Col md={6} lg={6}>
									<div className="card product-card">
										<div className="card-img-hover d-block" style={{aspectRatio: '16 / 9'}}>
											<img className="card-img-top" style={{margin: 0}} src={config.img2_acerca_nosotros} width="190" alt='' />
										</div>
										<div className="card-info">
											<div className="card-body">
												<div className="product-title">
													{config.descripcion_img2}
												</div>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						}
						{!policies ? <></>:
							<Row className="mb-5">
								<Col>
									<Card>
										<CardBody>
											<CardTitle tag="h5">Políticas</CardTitle>
											<ul className="card-body">
												{policies.map((value, key) => {
													return (
														<li key={'polity_'+key}>{value}</li>
													)
												})}
											</ul>
										</CardBody>
									</Card>
								</Col>
							</Row>
						}
						{!config.cancelaciones_devoluciones ? <></>:
							<Row className="mb-5">
								<Col>
									<Card>
										<CardBody>
											<CardTitle tag="h5">Cancelaciones y devoluciones</CardTitle>
											<div style={{overflow:"auto", resize:"none", opacity: 1}} >
												{config.cancelaciones_devoluciones}
											</div>
										</CardBody>
									</Card>
								</Col>
							</Row>
						}	
					</Container>
				}
			</section>
		</div>
		<Footer />
	</>);
}

export default About;