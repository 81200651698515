import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { Row, Button } from 'reactstrap';
import PriceProduct from './priceComponent';
import ToAddShoppingCart from './componentToAddShoppingCart';

const logo = require(`../assets/images/default-image.png`).default

const InformationProductList = ({ product, onClickQuickView }) => {
	const history = useNavigate()

	// Validations
	if (!product) return <></>

	const imageError = (currentTarget, product) =>{
		if (product.images) {
			if (product.images.length > 0) {
				currentTarget.src = product.images[0]
			}
		}else{
			currentTarget.src = logo
		}
	}

	const Image = ({ view }) => {
		if (!product.image) product.image = logo

		return (
			<img 
				className={"card-img-top card-img-"+view} 
				src={`${product.image}`} 
				alt={`${product.image}`} 
				width="100%" 
				height="270" 

				onError={({ currentTarget }) => {
					imageError(currentTarget, product)
				}}
			/>
		);
	}

	const productDetails = (product) => {
		if (!product) return console.error('product not found')

		localStorage.setItem('product_details', JSON.stringify(product))

		history('/productDetails/'+product.item_id)
	}

	return (
		<div className="card product-card product-list mb-5" style={{ width: '100%' }}>
			<Row className="align-items-center">
				<div className="col-lg-4 col-md-5">
					<div className="card-img-hover d-block" onClick={() => productDetails(product)}>
						<Image view="back"/>
						<Image view="front" />
					</div>
				</div>
				<div className="col-lg-8 col-md-7">
					<div className="card-info">
						<div className="card-body">
							<div className="product-title">
								<Link to="#" onClick={() => productDetails(product)} className="link-title">{product.name}</Link>
							</div>
							<div className="mt-1">
								<PriceProduct 
									original_price={product.original_price} 
									offerPrice={product.promo_price} 
									price={product.price} 
								/>
								<p className="mb-3 mt-2">{product.description}</p>
							</div>
							<div className="card-footer bg-transparent border-0">
								<div className="product-link d-flex align-items-left justify-content-left">
									<Button onClick={() => onClickQuickView(product)} className="btn btn-primary btn-view">
										<i className="las la-eye  mr-1" />
									</Button>
									<ToAddShoppingCart product={product} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Row>
		</div>
	);

}

export default InformationProductList;
