import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { Row, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import InformationProductCard from '../../../components/ProductInformationComponentCard';
import ImageGallery from 'react-image-gallery';
import OwlCarousel from 'react-owl-carousel';
import classnames from 'classnames';

import ToAddShoppingCart from '../../../components/componentToAddShoppingCart';
import InformationProductOnModal from '../../../components/ModalDetailProduct';
import { api, NoData, Loading, getCurrency, lan } from "../../../components/tools";
import PriceProduct from '../../../components/priceComponent';
import LevelsPrice from '../../../components/levelsPrice';
import HeaderPage from '../../../layout/header/headerPage';
import Footer from '../../../layout/footer/footer';

function ProductsDetails() {
	const [productsRelated, setProductsRelated] = useState(false)
	const [levels_price, setLevels_price] = useState(null)
	const [dataProduct, setDataProduct] = useState(null)
	const [viewProduct, setViewProduct] = useState("")
	const [variation, setVariation] = useState(null)
	const [activeTab, setActiveTab] = useState('1')
	const [elements, setElements] = useState(null)
	const [action, setAction] = useState(false)
	const [data, setData] = useState(false)
	const { productId } = useParams()

	const Options = {
		options: {
			loop: true,
			nav: true,
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 2,
				},
				1000: {
					items: 3,
				},
			},
		},
	}
	const currency = getCurrency()

	const getProduct = () => {
		// Get API products
		api({
			id: productId,
			url: 'products',
		}).then((resp) => {
			console.log('getProduct', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get product: " + resp.data.error)
				}

				// Save on local
				localStorage.setItem('product_details', JSON.stringify(resp.data[0]))

				// Launch logical
				getData()
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get products: " + err)
		})
	}

	const getProducts = () => {
		// Get API products
		api({
			category: dataProduct.category_id,
			url: 'products',
			limit_start: 0,
			limit_end: 10,
		}).then((resp) => {
			console.log('products', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get products: " + resp.data.error)
				}

				// JSON to array
				let result = [];
				for (let i in resp.data) result.push(resp.data[i])

				// Set products
				setProductsRelated(result)
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get products: " + err)
		})
	}

	const getData = () => {
		// Set product data
		const product_json = localStorage.getItem('product_details')
		if (!product_json){ 
			getProduct()

			return
		}

		const product = JSON.parse(product_json)

		if (product.levels_price){ 
			setLevels_price(product.levels_price)
		}else{
			setLevels_price(null)
		}

		setDataProduct(product)
		setData(product)
	}

	const toggle = tab => { if (activeTab !== tab) setActiveTab(tab) }

	const Carousel = () => {
		let _elements = []
		if (!dataProduct.full_image) {
			_elements.push({
				thumbnail: 'https://cdn.iconscout.com/icon/free/png-512/gallery-187-902099.png',
				original: 'https://cdn.iconscout.com/icon/free/png-512/gallery-187-902099.png',
			})
		} else {
			_elements.push({
				thumbnail: dataProduct.full_image,
				original: dataProduct.full_image,
				originalHeight: 350
			})
		}
	
		if (dataProduct.images) {
			for (const value of dataProduct.images) {
				_elements.push({
					originalHeight: 350,
					thumbnail: value,
					original: value,
				})
			}
		}

		setElements(_elements)
	}

	const onClickQuickView = (product) => {
		setAction(true);
		setViewProduct(product);
	}

	/* Components
	=============================================*/
	const ProductRelatedCarousel = () => {
		if (!productsRelated) return <NoData />

		return (
			<OwlCarousel
				className="no-pb owl-2"
				autoplay={true}
				dotData={false}
				dots={false}
				nav={true}
				{...Options.options}
			>
				{productsRelated.map((productRelated, i) => (
					<div className="item" key={i}>
						<InformationProductCard
							onClickQuickView={onClickQuickView}
							product={productRelated}
						/>
						<InformationProductOnModal
							quickView={() => setAction(!action)}
							toggle={() => setAction(!action)}
							viewProduct={viewProduct}
							action={action}
						/>
					</div>
				))}
			</OwlCarousel>
		);
	}

	const Variations = () =>{
		let variations = []
		for (const key in dataProduct.variations) {
			if (Object.prototype.hasOwnProperty.call(dataProduct.variations, key)) {
				const variation = dataProduct.variations[key]
				
				variations.push(
					<option value={variation.id} key={'variation_'+variation.id}>
						{variation.item_variation} - {currency+parseFloat(variation.price)}
					</option>
				)
			}
		}

		return <>
			<label>{lan('variation')}</label>
			<select className="form-control" name="variation" onChange={(e) => setVariation(e.target.value)} value={variation ?? ""}>
				{variations}
			</select>
			<ul className="list-unstyled my-4">
				<li className="mb-2">
					Disponibles: <span>{parseFloat(dataProduct.location_stock)}</span>
				</li>
			</ul>
		</>
	}
	
	/* Observers
	=============================================*/
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		if(dataProduct){ 
			Carousel()

			// If not selected variation, select the first
			if (!variation && dataProduct.variations) {
				setVariation(dataProduct.variations[0].id)
			}
		}
	}, [dataProduct])

	useEffect(() => {
		if(variation){
    		const variation_data = dataProduct.variations.find(item => item.id === parseInt(variation))
			if (!variation_data) return

			// Update item data
			setDataProduct({
				...dataProduct,
				variation_name: variation_data.name ? variation_data.name : variation_data.item_variation,
				location_stock: parseInt(variation_data.stock),
				price: parseFloat(variation_data.price),
				variation_id: parseInt(variation),
			})
		}
	}, [variation])

	// Get related products
	useEffect(() => {
		if (!productsRelated && dataProduct) getProducts()

		if (!data){
			getData()
		}else{
			if (parseInt(productId) !== parseInt(data.item_id)) getData()
			
        	window.scrollTo(0, 0);
		}
	}, [dataProduct])

	// Update data when change product
	useEffect(() => {
		if (productId) {
			if (data) {
				if (parseInt(productId) !== parseInt(data.item_id)) getData()
			}else{
				getData()
			}

        	window.scrollTo(0, 0);
		}
	}, [productId])

	return (
		<>
			<HeaderPage />
			<div className="page-content">
				{!data ?
					<Loading />
					:
					<section>
						<Container>
							<Row>
								<div className="col-lg-6 col-12" style={{height: "50; !important"}}>
									{!elements ? <></>:
										<ImageGallery
											preventDefaultTouchmoveEvent={true}
											showFullscreenButton={true}
											thumbnailPosition={"left"}
											showPlayButton={false}
											useTranslate3D={true}
											infinite={false}
											items={elements}
											showNav={true}
											video={true}
										/>
									}
								</div>
								<div className="col-lg-6 col-12 mt-5 mt-lg-0">
									<div className="product-details">
										<h3 className="mb-0">{dataProduct.name}</h3><br />
										<PriceProduct 
											original_price={dataProduct.original_price} 
											offerPrice={dataProduct.promo_price} 
											price={dataProduct.price} 
										/><br /><br />
										<LevelsPrice 
											levels_price={levels_price} 
											price={dataProduct.price} 
										/>
										{!dataProduct.variations ? 
												<ul className="list-unstyled my-4">
													<li className="mb-2">
														Disponibles: <span>{parseFloat(dataProduct.location_stock)}</span>
													</li>
												</ul>
											:
												<Variations />
										}
										<ToAddShoppingCart product={dataProduct} show_button={true} />
									</div>
								</div>
							</Row>
						</Container>
					</section>
				}
				<section className="p-0">
					<Container>
						<Row>
							<Col md={12}>
								<div className="tab">
									{/* Nav tabs */}
									<Nav tabs>
										<NavItem active>
											<NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }} >Descripción</NavLink>
										</NavItem>
									</Nav>
									{/* Tab panes */}
									<TabContent activeTab={activeTab} className="pt-5 p-0">
										<TabPane tabId="1" className="fade show" >
											<Row className="align-items-center">
												<div className="col-md-7 mt-5 mt-lg-0">
													{!dataProduct ? <></> :
														<p className="mb-5">
															{dataProduct.description}
														</p>
													}
												</div>
											</Row>
										</TabPane>
									</TabContent>
									<Container>
										<Row>
											<Col lg={8} md={10}>
												<div className="mb-5">
													<h4 className="text-primary mb-1">Productos relacionados </h4>
												</div>
											</Col>
										</Row>
									</Container>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<ProductRelatedCarousel />
							</Col>
						</Row>
					</Container>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default React.memo(ProductsDetails);