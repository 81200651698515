import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
	Container,
	CardBody,
	Button,
	Input,
	Card,
	Col,
	Row,
} from 'reactstrap';

import { api } from "../../components/tools";

const CustomerDataForm = () => {
	const userData = JSON.parse(localStorage.getItem("_client"));

	const [data, setData] = useState({
		phone_number: userData.phone_number,
		first_name: userData.first_name,
		last_name: userData.last_name,
		person_id: userData.person_id,
		country: userData.country,
		email: userData.email,
	});

	const updateData = e => {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	const sentData = (event) => {
		event.preventDefault();

		api({
			...data,
			url: 'updateClient',
			type: 'post',
		}).then((res) => {
			console.log('updateClient', res);
			
			// Validations
			if(!res){
				toast.error("Error al guardar los datos");

				return
			}

			if (res.data) {
				if (res.data.error) {
					toast.error("Error al guardar los datos");

					return
				}
			}

			// All ok
			toast.success("Datos guardados");
			
			const user = {
				...userData,
				...data
			}
			console.log('user', user);
			

			// Save new user data on local
			// localStorage.setItem("_client", JSON.stringify(data))
		}).catch((err) => {
			toast.error("Error al realizar operación");
		})
	}

	return (
		<Container>
			<Row>
				<Col lg={12} md={12} className="ml-auto mr-auto">
					<Card>
						<CardBody>
							<div className="register-form" >
								<form id="contact-form" onSubmit={sentData}>
									<input type="hidden" name="person_id" value={data.person_id} />
									<div className="messages" />
									<Row>
										<Col md={6}>
											<div className="form-group">
												<input
													data-error="El nombre es requerido."
													className="form-control"
													value={data.first_name}
													onChange={updateData}
													placeholder="Nombre"
													required="required"
													name="first_name"
													type="text"
												/>
												<div className="help-block with-errors" />
											</div>
										</Col>
										<Col md={6}>
											<div className="form-group">
												<input
													data-error="El apellido es requerido."
													className="form-control"
													placeholder="Apellido"
													required="required"
													name="last_name"
													type="text"

													onChange={updateData}
													value={data.last_name}
												/>
												<div className="help-block with-errors" />
											</div>
										</Col>
										<Col md={6}>
											<div className="form-group">
												<input
													data-error="El correo es requerido."
													placeholder="alguien@ecommpos.com"
													className="form-control"
													required="required"
													type="email"
													name="email"
													
													onChange={updateData}
													value={data.email}
												/>
												<div className="help-block with-errors" />
											</div>
										</Col>
										<Col md={6}>
											<Input
												data-error="El teléfono es requerido"
												className="form-control"
												placeholder="0123456789"
												required="required"
												name="phone_number"
												type="tel"
												
												value={data.phone_number}
												onChange={updateData}
											/>
										</Col>
										<Col md={6}>
											<div className="form-group">
												<select
													className="form-control"
													name="country"
													onChange={updateData}
													value={data.country}
												>
													<option value="Mexico">Mexico</option>
													<option value="Argentina">Argentina</option>
													<option value="China">China</option>
													<option value="Guatemala">Guatemala</option>
													<option value="United States">United States</option>
												</select>
											</div>
										</Col>
									</Row>
									<Row>
										<Col md={12}>
											<Button className='btn btn-primary' type="submit" color={"success"} >
												Guardar
											</Button>
										</Col>
									</Row>
								</form>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default React.memo(CustomerDataForm);