import React, { useState, useEffect, useCallback } from 'react';
import { Card, Button, CardTitle, Col, Row, Container } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { api, lan, Loading } from "../../components/tools";

const AddressList = ({ AddNewDirection, EditDirection, checkOut, CloseForm }) => {
	const user = JSON.parse(localStorage.getItem("_client"));

    const [dataAddress, setAddresses] = useState(false);

    const logicalEliminated = (address) => {
        api({
			...address,
			url: 'address',
			deleted: 1,
			type: 'put'
		}).then((resp) => {
			console.log('saveAddress', resp);

			if (!resp.data) {
				toast.error("Error al guardar la dirección");

				return;
			}
			if (resp.data.error) {
				toast.error("Error al guardar la dirección");

				return;
			}

			// All ok
			localStorage.removeItem("_addresses")
            setAddresses(false)
			CloseForm()
		}).catch((err) => {
			console.log('err', err);

			toast.error("Error al guardar la dirección");
		})
    }

    const getAddresses = useCallback(() => {
		// Get local addresses
		let local_addresses = localStorage.getItem("_addresses");
		if (local_addresses) {
			const _addresses = JSON.parse(local_addresses)

			setAddresses(_addresses)

			return _addresses
		}

		// Get API addresses
		api({
            customer_id: user.person_id,
			url: 'address',
		}).then((resp) => {
			console.log('addresses', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get addresses: " + resp.data.error)
				}

				// JSON to array
				let result = [];
				for (let i in resp.data) result.push(resp.data[i])

				// Set addresses
				setAddresses(result)

				// Save addresses on local
				localStorage.setItem("_addresses", JSON.stringify(result));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get addresses: " + err)
		})
	}, [setAddresses, user.person_id])

    let history = useNavigate();
    const selectAddress = (address) => {
        console.log('address', address);

        localStorage.setItem('_address', JSON.stringify(address))

        history("/summary");
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        if (!dataAddress) getAddresses()
    }, [dataAddress, getAddresses]);

    return (
        <Container>
            <Row>
                {!dataAddress ? <Loading /> : 
                    dataAddress.map((address, i) => (
                        <Col xl={3} lg={4} md={6} key={i}>
                            <Card body>
                                <CardTitle tag="h5">
                                    {(!address.name) ? <></> :
                                        (address.name.length > 19) ?
                                            address.name.substring(0, 18) + "..."
                                            :
                                            (address.name)
                                    }
                                </CardTitle>
                                <div className="mb-1 mt-1">
                                    {address.address}
                                    {address.city ? ', '+address.city : ''}
                                    {address.state ? ', '+address.state : ''}
                                </div>
                                {!checkOut ? <></>:
                                    <Row>
                                        <Col md={12}>
                                            <Button color="success" style={{ display: checkOut ? 'inline' : 'none' }} size="sm" onClick={() => selectAddress(address)} block>
                                                <i className="las la-truck"></i> Enviar aquí
                                            </Button>
                                        </Col>
                                    </Row>
                                }
                                <Row className='p-2'>
                                    <Col className='p-1'>
                                        <Button className='btn-block' color="primary" size="sm" onClick={() => EditDirection(address)}>
                                            <i className="las la-pencil-alt"></i>
                                        </Button>
                                    </Col>
                                    <Col className='p-1'>
                                        <Button className='btn-block'  color="danger" size="sm" type="submit" onClick={() => logicalEliminated(address)}>
                                            <i className="las la-trash"></i>
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    )
                )}

                <Col xl={3} lg={4} md={6} onClick={() => AddNewDirection()}>
                    <Card body className='h-100 text-center rounded' style={{borderStyle: 'dashed', borderWidth: '5px'}}>
                        <div style={{paddingTop: '20%'}}>
                            <i className="las la-plus" style={{fontSize: '40px', fontWeight: 'bold', paddingTop: 10}}></i>
                            <p>
                                {lan('add')}
                            </p>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default React.memo(AddressList);