import React, { useState, useEffect, useCallback } from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Row, Container } from 'reactstrap';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

import { NoData, getCurrency } from '../../components/tools';

const Sale = () => {
	/* Hooks
	======================================== */
	const [saleData, setSaleData] = useState(null)

	/* Functions
	======================================== */
	const GetCartItems = () => {
		return saleData.products
	}

	const getData = () =>{
		console.log('getData')

		// Get sale data
		let local_sale = localStorage.getItem("_sale");
		if (local_sale) {
			const sale = JSON.parse(local_sale)

			setSaleData(sale)

			return
		}
	}

	const InformationAddressColumOne = (data) => {
		let informationOneColum = data.data.address ? data.data.address : "";

		if (informationOneColum.length > 50) {
			let oneInfoAddress = informationOneColum.substring(0, 50);
			return (<>
				<i className="lar la-circle" />{' '}{oneInfoAddress}{'...'}
			</>)
		} else {
			return (<>
				<i className="lar la-circle" />{' '}{informationOneColum}
			</>)
		}
	}

	const InformationAddressColumTwo = (data) => {
		let informationTwoColum = "";
		let town = (data.data.city !== null ? data.data.city : "");
		let suburb = (data.data.suburb ? ", " + data.data.suburb : "");
		informationTwoColum = town + suburb;

		if (informationTwoColum.length > 25) {
			let twoInfoAddress = informationTwoColum.substring(0, 25)

			return (<>
				<i className="lar la-circle" />{' '}{twoInfoAddress}{'...'}
			</>)
		} else {
			return (<>
				<i className="lar la-circle" />{' '}{informationTwoColum}
			</>)
		}
	}

	const InformationAddressColumThree = (data) => {
		let informationThreeColum = "";
		let state = (data.data.state !== null ? data.data.state + "," : "");
		let country = (data.data.country !== null ? data.data.country + "," : "");
		let postal_code = (data.data.zip !== null ? data.data.zip : "")
		informationThreeColum = state + country + postal_code;

		if (informationThreeColum.length > 25) {
			let threeInfoAddress = informationThreeColum.substring(0, 25);
			return (
				<>
					<i className="lar la-circle" />{' '}{threeInfoAddress}{'...'}
				</>
			)
		} else {
			return (
				<>
					<i className="lar la-circle" />{' '}{informationThreeColum}
				</>
			)
		}
	}

	/* Components
	======================================== */
	const GetAddress = () => {
		const address = saleData.address

		return (
			<>
				<h6 className="text-primary mb-1">Envió</h6>
				<Card>
					<CardBody>
						<CardTitle tag="h5"><i className="las la-home"></i> {address.name}</CardTitle>
						<CardText>
							<InformationAddressColumOne data={address} /><br />
							<InformationAddressColumTwo data={address} /><br />
							<InformationAddressColumThree data={address} /><br />
						</CardText>
					</CardBody>
				</Card>
			</>
		)
	}

	const GetPayment = () => {
		return (
			<Card>
				<CardBody>
					<Row>
						<Col >
							<div className="d-flex justify-content-between align-items-center border-bottom py-3">
								<span>Subtotal</span>
								<span>
									{getCurrency()}{GetCartItems().reduce((fr, CartItem) => fr + (parseFloat(CartItem.quantity) * parseFloat(CartItem.price)), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
								</span>
							</div>
							<div className="d-flex justify-content-between align-items-center border-bottom py-3">
								<span>Tax</span>
								<span>{getCurrency()}{GetCartItems().reduce((fr, CartItem) => fr + (parseFloat(CartItem.quantity) * parseFloat(CartItem.tax)), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
							</div>
							<div className="d-flex justify-content-between align-items-center border-bottom py-3">
								<span>Envió</span>
								<span>{getCurrency()}0</span>
							</div>
							<div className="d-flex justify-content-between align-items-center pt-3 mb-5">
								<span>Total</span>
								<span className="font-weight-bold">
									{getCurrency()}{GetCartItems().reduce((fr, CartItem) => fr + (parseFloat(CartItem.quantity) * parseFloat(CartItem.price)), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
								</span>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		)
	}

	/* Observers
	======================================== */
	useEffect(() => {
		window.scrollTo(0, 0)

		if(!saleData) getData()
	}, [saleData]);

	return (
		<div className="page-content">
			<HeaderPage />
			<section>
				<Container>
					{!saleData ? <NoData /> :
						<Row>
							<Col md={12} lg={12}>
								<Row>
									<Col md={6}>
										<GetAddress />
									</Col>
									<Col md={6}>
										<GetPayment />
									</Col>
								</Row>
							</Col>
							<Col md={12} lg={12} style={{ paddingTop: 50 }}>
								<h6 className="text-primary mb-1">Productos</h6>
							</Col>
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>Producto</th>
											<th>Precio</th>
											<th>Cantidad</th>
											<th>Total</th>
										</tr>
									</thead>
									<tbody>
										{GetCartItems().map((CartItem, index) => {
											const quantity = parseInt(CartItem.quantity)
											const price = parseFloat(CartItem.price)

											return (
												<tr key={'item_'+index}>
													<td>{CartItem.name}</td>
													<td>{getCurrency()}{price}</td>
													<td>{quantity}</td>
													<td>{getCurrency()}{(price * quantity)}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</Row>
					}
				</Container>
			</section>
			<Footer />
		</div>
	)
}

export default Sale;