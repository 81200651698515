import React from 'react';
import HeaderPage from '../../layout/header/headerPage';
import HelperDirection from './helperForAddresses';
import Footer from '../../layout/footer/footer';

function HomeDirection({token}){
    return(
        <>
			<HeaderPage />
            <HelperDirection token={token} checkOut={true} />
			<Footer />
        </>
    );
}

export default React.memo(HomeDirection);