import React from 'react'
import { Row, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import PriceProduct from './priceComponent';

const logo = require(`../assets/images/default-image.png`).default

const InformationProductOnModal = ({ action, toggle, viewProduct, quickView }) => {
	// Validations
	if (!viewProduct) return <></>

	const imageError = (currentTarget, product) => {
		if (product.images) {
			if (product.images.length > 0) {
				currentTarget.src = product.images[0]
			}
		} else {
			currentTarget.src = logo
		}
	}

	return (
		<Modal isOpen={action} toggle={toggle} className="view-modal">
			<ModalHeader className="border-bottom-0 pb-0">
				<Button className="close" color="danger" onClick={() => quickView()} ><span aria-hidden="true">×</span></Button>
			</ModalHeader>
			<ModalBody>
				<Row className="align-items-center">
					<div className="col-lg-7 col-12">
						<img
							className="img-fluid rounded"
							src={`${viewProduct.image}`}
							height="900"
							width="950"
							alt="..."

							onError={({ currentTarget }) => {
								imageError(currentTarget, viewProduct)
							}}
						/>
					</div>
					<div className="col-lg-5 col-12 mt-5 mt-lg-0">
						<div className="product-details">
							<h3 className="mb-0">{viewProduct.name}</h3>
							<div className="star-rating mb-4">
								<i className="las la-star" />
								<i className="las la-star" />
								<i className="las la-star" />
								<i className="las la-star" />
								<i className="las la-star" />
							</div>
							<PriceProduct 
								original_price={viewProduct.original_price} 
								offerPrice={viewProduct.promo_price} 
								price={viewProduct.price} 
							/>
							<ul className="list-unstyled my-4">
								<li className="mb-2">
									Stock: <span>{parseFloat(viewProduct.location_stock)}</span>
								</li>
							</ul>
							<p className="mb-4">{viewProduct.description}</p>
						</div>
					</div>
				</Row>
			</ModalBody>
		</Modal>
	);
}

export default InformationProductOnModal;

