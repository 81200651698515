import React from 'react'
import ToAddShoppingCart from './componentToAddShoppingCart';
import PriceProduct from './priceComponent';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';

const logo = require(`../assets/images/default-image.png`)

const InformationProductCard = ({ product, onClickQuickView }) => {
	const history = useNavigate()

	// Validations
	if (!product) return <></>

	const imageError = (currentTarget, product) =>{
		if (product.images) {
			if (product.images.length > 0) {
				currentTarget.src = product.images[0]
			}else{
				if(logo) currentTarget.src = logo
			}
		}else{
			if(logo) currentTarget.src = logo
		}
	}
	
	const Image = ({ view }) => {
		if (!product.image) product.image = logo

		return (
			<img 
				className={"card-img-top card-img-"+view} 
				style={{borderRadius: '5%'}}
				src={`${product.image}`} 
				alt={`${product.image}`} 
				width="100%" 
				height="270" 

				onError={({ currentTarget }) => {
					imageError(currentTarget, product)
				}}
			/>
		);
	}

	const productDetails = (product) =>{
		if (!product) return console.error('product not found')

		localStorage.setItem('product_details', JSON.stringify(product))

		history('/productDetails/'+product.item_id)
	}

	// EC-961 Variation expand btn button
	const btn_block = product.variations ? 'btn-block' : ''

	return (
		<div className="card product-card">
			<div className="card-img-hover" onClick={() => productDetails(product)}>
				<Image view="back"/>
				<Image view="front" />
			</div>
			<div className="card-info">
				<div className="card-body">
					<div className="mb-1 text-left">
						<PriceProduct 
							original_price={product.original_price} 
							offerPrice={product.promo_price} 
							price={product.price} 
						/>
					</div>
					<div className="product-title text-left">
						<Link to="#" onClick={() => productDetails(product)} className="link-title">{product.name}</Link>
					</div>					
				</div>
				<div className="card-footer bg-transparent border-0">
					<div className="product-link d-flex align-items-center justify-content-center">
						<Button onClick={() => onClickQuickView(product)} className={"btn btn-primary btn-view "+btn_block}>
							<i className="las la-eye  mr-1" />
						</Button>
						<ToAddShoppingCart product={product} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default InformationProductCard;
