import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardText, Button, CardTitle, Col, Row, Container } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom'

import { api, getCurrency, lan } from "../../components/tools";
import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';
import CardPayment from "./CardPayment";

const logo = require(`../../assets/images/default-image.png`).default

const Summary = () => {
	const history = useNavigate()

	// Get current date
	let today = new Date();
	let dd = String(today.getDate()).padStart(2, '0');
	let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	let yyyy = today.getFullYear();
	today = yyyy + '-' + mm + '-' + dd;

	/* Hooks
	======================================== */
	const [deliveryDate, setDeliveryDate] = useState(today)
	const [disabled, setDisabled] = useState(false)
	const [config, setConfig] = useState(false)
	const [secret, setSecret] = useState(null)
	const [payment, setPayment] = useState()

	/* Functions
	======================================== */
	const GetCartItems = () => {
		return JSON.parse(localStorage.getItem("CartProduct"));
	}

	const InformationAddressColumOne = (data) => {
		let informationOneColum = data.data.address ? data.data.address : "";

		if (informationOneColum.length > 50) {
			let oneInfoAddress = informationOneColum.substring(0, 50);
			return (<>
				<i className="lar la-circle" />{' '}{oneInfoAddress}{'...'}
			</>)
		} else {
			return (<>
				<i className="lar la-circle" />{' '}{informationOneColum}
			</>)
		}
	}

	const InformationAddressColumTwo = (data) => {
		let informationTwoColum = "";
		let town = (data.data.city !== null ? data.data.city : "");
		let suburb = (data.data.suburb ? ", " + data.data.suburb : "");
		informationTwoColum = town + suburb;

		if (informationTwoColum.length > 25) {
			let twoInfoAddress = informationTwoColum.substring(0, 25)

			return (<>
				<i className="lar la-circle" />{' '}{twoInfoAddress}{'...'}
			</>)
		} else {
			return (<>
				<i className="lar la-circle" />{' '}{informationTwoColum}
			</>)
		}
	}

	const InformationAddressColumThree = (data) => {
		let informationThreeColum = "";
		let state = (data.data.state !== null ? data.data.state + "," : "");
		let country = (data.data.country !== null ? data.data.country + "," : "");
		let postal_code = (data.data.zip !== null ? data.data.zip : "")
		informationThreeColum = state + country + postal_code;

		if (informationThreeColum.length > 25) {
			let threeInfoAddress = informationThreeColum.substring(0, 25);
			return (
				<>
					<i className="lar la-circle" />{' '}{threeInfoAddress}{'...'}
				</>
			)
		} else {
			return (
				<>
					<i className="lar la-circle" />{' '}{informationThreeColum}
				</>
			)
		}
	}

	const Pay = () => {
		let date = new Date()
		date.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });

		// Init vars
		const address = JSON.parse(localStorage.getItem("_address"))
		const user = JSON.parse(localStorage.getItem("_client"))
		const products = GetCartItems()

		// Validations
		if (!user) {
			console.error('No user')

			return;
		}

		// Get current date
		let delivery_date = new Date(deliveryDate);
		let dd = String(delivery_date.getDate()).padStart(2, '0');
		let mm = String(delivery_date.getMonth() + 1).padStart(2, '0');
		let yyyy = delivery_date.getFullYear();
		delivery_date = dd + '-' + mm + '-' + yyyy;

		// Payment type
		const total = GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * parseFloat(CartItem.price)), 0)
		let payment_type = ""
		switch (payment) {
			case 'card':
				payment_type = "Tarjeta de crédito: " + getCurrency() + total + "<br />"
				break;
			case 'upon_delivery':
				payment_type = "Contra entrega: " + getCurrency() + total + "<br />"
				break;
			case 'transfer':
				payment_type = "Transferencia: " + getCurrency() + total + "<br />"
				break;

			default:
				break;
		}

		let data = {
			comment: lan('sale_from_ecommerce'),
			delivery_date: delivery_date,
			payment_type: payment_type,
			from: "Ecommerce",
			address: address,
			payment: payment,
			products: [],
		}

		products.map((value) => {
			// EC-961 Variation data
			let id = value.item_id
			if (value.variation_id) id +='-'+value.variation_id

			data.products.push({
				cost_price: parseFloat(value.cost_price),
				variation_id: value.variation_id ?? null,
				price: parseFloat(value.price),
				quantity: value.Qty,
				name: value.name,
				id: id,
				tax: 0,
			})

			return value
		})

		// Loading
		setDisabled(true)

		api({
			...data,
			url: 'newSale',
			type: 'post',
		}).then((resp) => {
			console.log('resp', resp);

			// Loading
			setDisabled(false)

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					console.log('error');

					return
				}

				// Clean data
				localStorage.removeItem("_productsMostSold")
				localStorage.removeItem("_productsRating")
				localStorage.removeItem("product_details")
				localStorage.removeItem('_customerSales')
				localStorage.removeItem("_productsNew")
				localStorage.removeItem("_allProducts")
				localStorage.removeItem("CartProduct")
				localStorage.removeItem('_address');

				history("/orderComplete")
			}
		}).catch((err) => {
			console.log('err', err);

			// Loading
			setDisabled(false)
		})
	}

	const getConfig = () => {
		// Get local config
		let local_config = localStorage.getItem("_config");
		if (local_config) {
			const _config = JSON.parse(local_config)

			setConfig(_config)

			// Get stripe secret
			if (_config.showStripe) getSecret()

			return _config
		}

		// Get API config
		api({
			url: 'config',
		}).then((resp) => {
			console.log('config', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get config: " + resp.data.error)
				}

				// Set config
				setConfig(resp.data)

				// Save config on local
				localStorage.setItem("_config", JSON.stringify(resp.data))

				// Get stripe secret
				if (resp.data.showStripe) getSecret()
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get config: " + err)
		})
	}
	
	const getSecret = () =>{
		const amount = GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * parseFloat(CartItem.price)), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })

		// Get local stripe data
		let local_data = localStorage.getItem("stripe_secret")
		if (local_data) {
			const stripe_secret = JSON.parse(local_data)

			// Same amount load cache
			if (stripe_secret.amount === amount) {
				setSecret(stripe_secret.secret)

				return false
			}
		}

		api({
			amount: amount,
			url: 'payment',
			type: 'post',
		}).then((resp) => {
			console.log('stripe/payment', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					console.log('error');

					return
				}

				// Update hook
				setSecret(resp.data.clientSecret)

				// Save on local
				const secret_data = {
					secret: resp.data.clientSecret,
					amount: amount
				}
				localStorage.setItem("stripe_secret", JSON.stringify(secret_data))
			}
		})
	}

	/* Components
	======================================== */
	const GetAddress = () => {
		const address = JSON.parse(localStorage.getItem("_address"))
		if (!address) {
			console.error("No address");

			return;
		}

		return (
			<>
				<h6 className="text-primary mb-1">Envió</h6>
				<Card>
					<CardBody>
						<CardTitle tag="h5"><i className="las la-home"></i> {address.name}</CardTitle>
						<CardText>
							<InformationAddressColumOne data={address} /><br />
							<InformationAddressColumTwo data={address} /><br />
							<InformationAddressColumThree data={address} /><br />
						</CardText>
						<Link color="success" size="sm" to="/direction">
							<i className="las la-edit"></i> Cambiar
						</Link><br /><br />

						<CardTitle tag="h5"><i className="las la-truck"></i> Estándar</CardTitle>
						<CardText>
							{getCurrency()} 0
						</CardText>
					</CardBody>
				</Card>
			</>
		)
	}

	const PaymentsType = () => {
		if (!config || !payment) return <p>Seleccione un método de pago</p>

		switch (payment) {
			case 'card':
				localStorage.setItem("deliveryDate", deliveryDate)
				return <CardPayment secret={secret} />

			case 'upon_delivery':
				return <></>

			case 'transfer':
				if (!config.messageTransferMethod) {
					console.warn("No messageTransferMethod")

					setPayment("")
				}
				return <p className="col-12">{config.messageTransferMethod}</p>
		
			default:
				return <p>Seleccione otro método de pago</p>
		}
	}

	const GetPayment = () => {
		return (
			<>
				<h6 className="text-primary mb-1">Pago</h6>
				<Card>
					<CardBody>
						<Row>
							<Col >
								<div className="d-flex justify-content-between align-items-center border-bottom py-3">
									<span>Subtotal</span>
									<span>
										{getCurrency()}{GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * parseFloat(CartItem.price)), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
									</span>
								</div>
								<div className="d-flex justify-content-between align-items-center border-bottom py-3">
									<span>Tax</span>
									<span>{getCurrency()}0</span>
								</div>
								<div className="d-flex justify-content-between align-items-center border-bottom py-3">
									<span>Envió</span>
									<span>{getCurrency()}0</span>
								</div>
								<div className="d-flex justify-content-between align-items-center pt-3 mb-5">
									<span>Total</span>
									<span className=" font-weight-bold">
										{getCurrency()}{GetCartItems().reduce((fr, CartItem) => fr + (CartItem.Qty * parseFloat(CartItem.price)), 0).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
									</span>
								</div>
								<h5>Fecha de entrega</h5>
								<input
									defaultValue={deliveryDate}
									className="form-control"
									placeholder=''
									type="date"
									min={today}

									onBlur={e => setDeliveryDate(e.target.value)}
								/><br />
								<h5>Método de pago</h5>
								{!config ? <></> : <>
									<select
										className="custom-select border-0 rounded-0 form-control d-lg-inline"
										onChange={e => setPayment(e.target.value)}
										defaultValue={payment}
									>
										<option key={`empty`} value=""> - Seleccionar</option>
										{config.showContraEntrega === 'yes' ?
											<option key={`upon_delivery`} value="upon_delivery">Contra entrega</option> : <></>
										}
										{config.showStripe === 'yes' ?
											<option key={`card`} value="card">Pago con tarjeta</option> : <></>
										}
										{config.showTransfer === 'yes' ?
											<option key={`transfer`} value="transfer">Transferencia</option> : <></>
										}
									</select><br /><br />
									<PaymentsType />
									{!payment || payment == 'card' ? <></> :
										<Button disabled={disabled} className="btn btn-primary mr-3" onClick={Pay} block>Finalizar compra</Button>
									}
								</>}
							</Col>
						</Row>
					</CardBody>
				</Card>
			</>
		)
	}

	const imageError = (currentTarget, product) => {
		if (product.images) {
			if (product.images.length > 0) {
				currentTarget.src = product.images[0]
			}
		} else {
			currentTarget.src = logo
		}
	}

	/* Observers
	======================================== */
	useEffect(() => {
		window.scrollTo(0, 0)

		if (!config) getConfig()
	}, [config]);

	return (
		<div className="page-content">
			<HeaderPage />
			<section>
				<Container>
					{(GetCartItems() !== null && GetCartItems().length > 0) ?
						<Row>
							<Col md={12} lg={12}>
								<Row>
									<Col md={6}>
										<GetAddress />
									</Col>
									<Col md={6}>
										<GetPayment />
									</Col>
								</Row>
							</Col>
							<Col md={12} lg={12} style={{ paddingTop: 50 }}>
								<h6 className="text-primary mb-1">Productos</h6>
							</Col>
							<div className="table-responsive">
								<table className="cart-table table">
									<thead>
										<tr>
											<th scope="col">Producto</th>
											<th scope="col">Precio</th>
											<th scope="col">Cantidad</th>
											<th scope="col">Total</th>
										</tr>
									</thead>
									<tbody>
										{GetCartItems().map((CartItem, index) => {
											let name = CartItem.name

											// EC-961 Variation name
											if (CartItem.variation_name) name = CartItem.name+' - '+CartItem.variation_name
								
											return (
												<tr key={index}>
													<td>
														<div className="media align-items-center">
															<img 
																style={{ height: '50px', width: '50px' }} 
																className="img-fluid" 
																src={CartItem.image} 
																alt="" 

																onError={({ currentTarget }) => {
																	imageError(currentTarget, CartItem)
																}}
															/>
															<div className="media-body ml-3">
																<div className="product-title mb-2">{name}</div>
															</div>
														</div>
													</td>
													<td>
														{getCurrency()}{parseFloat(CartItem.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
													</td>
													<td>
														<div className="d-flex justify-content-center align-items-center">
															{CartItem.Qty}
														</div>
													</td>
													<td>
														{getCurrency()}{(parseFloat(CartItem.price) * CartItem.Qty).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</Row>
						:
						<Row>
							<Col md={12} className="text-center pb-11">
								<h3 className="mb-4">Su carrito está vacío.</h3>
								<Link className="btn btn-primary mr-3" to="/">Inicio</Link>
								<Link className="btn btn-primary" to="/productsCategories">Continuar comprando</Link>
							</Col>
						</Row>
					}
				</Container>
			</section>
			<Footer />
		</div>
	)
}

export default Summary;