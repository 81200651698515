import React, { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

import { api, Message } from "../../components/tools";

const Login = () => {
	const history = useNavigate()

	/* Hooks
	======================================== */
	const [disabled, setDisabled] = useState(false)
	const [message, setMessage] = useState(false)
	const [dataMessage, setDataMessage] = useState({
		message: 'Ocurrió un error',
		title: 'Error',
	})
	const [data, setData] = useState({
		pass: '',
		tel: '',
	})

	/* Functions
	======================================== */

	// Function that ejecute when the user write an input
	const updateData = e => {
		setData({
			...data,
			[e.target.name]: e.target.value
		});
	}

	// Login
	const handleSubmit = (e) => {
		e.preventDefault();

		// Loading
		setDisabled(true)

		api({
			...data,
			url: 'clients'
		}).then((res) => {
			console.log('res', res);

			// Loading
			setDisabled(false)

			if (res.data.length < 1) {
				setDataMessage({
					message: 'Datos incorrectos',
					title: 'Error',
				})
				setMessage(true)

				return;
			}

			// Save user
			localStorage.setItem("_client", JSON.stringify(res.data[0]))

			// Clear products
			localStorage.removeItem("_productsMostSold")
			localStorage.removeItem("_productsRating")
			localStorage.removeItem("product_details")
			localStorage.removeItem("_productsNew")
			localStorage.removeItem("_allProducts")

			// Clear form
			setData({
				pass: '',
				tel: '',
			})

			// Redirect to home
			goHome()
		}).catch((err) => {
			console.error(err)
			
			// Loading
			setDisabled(false)

			// Show error
			setDataMessage({
				message: 'Algo salio mal, intenta mas tardes',
				title: 'Error',
			})
			setMessage(true)
		})
	}

	// Send login user to home
	const goHome = () => {
		if (localStorage.getItem("CartProduct")) {
			localStorage.setItem("_update_prices", "1")
			history("/cart");
		} else {
			history("/");
		}
	}

	return (
		<>
			<HeaderPage />
			<div className="page-content">
				<section>
					<Container>
						<Row className="justify-content-center">
							<div className="col-lg-5">
								<div className="shadow p-3">
									<form id="contact-form" method="post" onSubmit={handleSubmit}>
										<div className="messages" />
										<h3 className='text-center' style={{color: "var(--color_theme)"}}><strong>Login</strong></h3>
										<div className="form-group">
											<input
												data-error="Tel is required."
												placeholder="0123456789"
												className="form-control"
												required="required"
												type="number"
												name="tel"
												id="tel"

												onChange={updateData}
												value={data.tel}
											/>
										</div>
										<div className="form-group">
											<input
												data-error="password is required."
												className="form-control"
												placeholder="Password"
												required="required"
												type="password"
												name="pass"
												id="pass"

												onChange={updateData}
												value={data.pass}
											/>
										</div>
										{!message ? <></> : 
											<Row>
												<Col xs={12} className="mb-3 ml-auto mr-auto text-center" style={{color: "var(--danger)"}}>
													<strong>{dataMessage.title}:</strong> {dataMessage.message}
												</Col>
											</Row>
										}
										<button disabled={disabled} type="submit" className="btn btn-primary btn-block">
											{(disabled) ? 'Cargando...' : 'Iniciar sesión'}
										</button>
										<div className="d-flex align-items-center text-center justify-content-center mt-4">
											<Link to="/recovery">Recuperar cuenta</Link>
										</div>
									</form>
									<div className="d-flex align-items-center text-center justify-content-center mt-4">
										<span className=" mr-1">¿No tienes cuenta?</span>
										<Link to="/sign-up">Crear cuenta</Link>
									</div>
								</div>
							</div>
						</Row>
					</Container>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default Login;