import React, { useState, useEffect } from 'react';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";

import "./App.css";
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-toastify/dist/ReactToastify.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './assets/css/theme-plugin.css';
import './assets/css/theme.min.css';

import PrivateRoute from './PrivateRoute';
import GetToken from './components/Admin'

import ProductsFilterCategory from './pages/shop/layout/grid/productsFilter';
import ProductSearchByIdName from './pages/productSearch/ProductSearch';
import ProductsDetails from './pages/shop/product/productDetails';
import MainCustomerData from './pages/account/mainCustomerData';
import RecoverPassword from './pages/account/recoverPassword';
import HomeDirection from './pages/account/homeAddressPage';
import StripeComplete from './pages/shop/StripeComplete';
import OrderComplete from './pages/shop/orderComplete';
import Contact from './pages/contact/Contact';
import SignUp from './pages/account/SignUp';
import Offers from './pages/offers/offers';
import Summary from './pages/shop/Summary'
import Login from './pages/account/Login';
import About from './pages/common/About';
import Cart from './pages/shop/cart';
import Sale from './pages/shop/Sale'
import Home from './pages/home/home';
import PageNotFound from './pages/common/page-not-found';


const router = createBrowserRouter([
	{
		errorElement: <PageNotFound />,
		element: <Home />,
		path: "/",
	},
	{
		element: <OrderComplete />,
		path: "/OrderComplete",
	},
	{
		element: <StripeComplete />,
		path: "/stripe/complete",
	},
	{
		element: <Contact />,
		path: "/contact",
	},
	{
		element: <HomeDirection />,
		path: "/direction",
	},
	{
		element: <ProductsFilterCategory />,
		path: "/category/:nombre/:category",
	},
	{
		element: <ProductsDetails />,
		path: "/productDetails/:productId",
	},
	{
		element: <ProductSearchByIdName />,
		path: "/search/:search",
	},
	{
		element: <Cart />,
		path: "/cart",
	},
	{
		element: <SignUp />,
		path: "/sign-up",
	},
	{
		element: <Login />,
		path: "/login",
	},
	{
		element: <RecoverPassword />,
		path: "/recovery",
	},
	{
		element: <MainCustomerData />,
		path: "/customer",
	},
	{
		element: <Sale />,
		path: "/sale",
	},
	{
		element: <About />,
		path: "/about",
	},
	{
		element: <Summary />,
		path: "/summary",
	},
]);

function App() {
	const [token, setToken] = useState(false)

	const getToken = () => {
		// Check local token
		let local_token = localStorage.getItem("_token")
		if (local_token) {
			// Update hooks
			setToken(local_token)

			return
		}

		// Get token from API
		GetToken().then(response => {
			// Validations
			if (response.error) {
				console.log('error', response);

				throw new Error("Error on get token")
			}
			if (response.data.length <= 0) {
				console.log('No valid customer:', response);
				throw new Error("No valid customer")
			}

			// Set token
			if (response.data[0]) {
				localStorage.setItem("_store", JSON.stringify(response.data[0]))
				localStorage.setItem("_token", response.data[0].token)

				setToken(response.data[0].token)
			}
		}).catch(error => {
			console.log('An error occurred:', error);
			throw new Error("Error on get token")
		});
	}

	useEffect(() => {
		if (!token){
			// Preserve items
			const addresses = localStorage.getItem("_addresses")
			const address = localStorage.getItem("_address")
			const cart = localStorage.getItem("CartProduct")
			const client = localStorage.getItem("_client")

			// Clear data first time
			localStorage.clear()

			// Preserve items
			if(addresses) localStorage.setItem("_addresses", addresses)
			if(address) localStorage.setItem("_address", address)
			if(client) localStorage.setItem("_client", client)
			if(cart) localStorage.setItem("CartProduct", cart)
			localStorage.setItem('totalPerPage', 28)
			localStorage.setItem('items_home', 8)

			getToken()
		}
	}, [token])

	return (<>
		{!token ? <></> : 
			<RouterProvider router={router} />
		}
	</>);
}

export default App;
