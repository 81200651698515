import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import InformationProductCard from '../../components/ProductInformationComponentCard'
import InformationproductnModal from '../../components/ModalDetailProduct'
import { api, NoData } from "../../components/tools";
import OwlCarousel from 'react-owl-carousel'

function NewProducts() {
	const [viewProduct, setViewProduct] = useState('')
	const [products, setProducts] = useState(false);
	const [category, setCategory] = useState({})
	const [action, setAction] = useState(false)

	const Option = {
		options: {
			loop: true,
			nav: true,
			dots: false,
			responsive: {
				0: {
					items: 1,
				},
				600: {
					items: 2,
				},
				1000: {
					items: 3,
				},
			},
		},
	}

	const getProducts = () => {
		// Get local products
		let local_products = localStorage.getItem("_productsNew");
		if (local_products) {
			const _products = JSON.parse(local_products)

			setProducts(_products)

			return _products
		}

		// Get API products
		const limit_end = localStorage.getItem('items_home')
		api({
			order: 'i.last_modified DESC',
			limit_end: limit_end,
			url: 'products',
			limit_start: 0,
		}).then((resp) => {
			console.log('NewProducts', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get products: " + resp.data.error)
				}

				// JSON to array
				let result = [];
				for (let i in resp.data) result.push(resp.data[i])

				// Set products
				setProducts(result)
				
				// Save products on local
				localStorage.setItem("_productsNew", JSON.stringify(result));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get products: " + err)
		})
	}

	useEffect(() => {
		if (!products) getProducts()
	}, [products]);

	const quickView = () => {
		setAction(!action)
	}

	const toggle = () => setAction(!action)

	const onClickQuickView = (product) => {
		setAction(true)
		setViewProduct(product)
		setCategory(product.category)
	}

	const Carousel = () => {
		if (!products) return <NoData />

		return (
			<OwlCarousel
				className="no-pb owl-2"
				dotData={false}
				autoplay={true}
				dots={false}
				nav={true}
				{...Option.options}
				navText={[
					"<span class='la la-angle-left'><span></span></span>",
					"<span class='la la-angle-right'><span></span></span>",
				]}
			>
				{
					products.map((product, i) => (
						<div className="item" key={i}>
							<InformationProductCard
								onClickQuickView={onClickQuickView}
								product={product}
							/>
							<InformationproductnModal
								viewProduct={viewProduct}
								quickView={quickView}
								category={category}
								action={action}
								toggle={toggle}
							/>
						</div>
					))
				}
			</OwlCarousel>
		)
	}

	return (
		<Row>
			<Col>
				<Carousel />
			</Col>
		</Row>
	)
}

export default React.memo(NewProducts)
