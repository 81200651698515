import React from 'react';

import { getCurrency } from './tools'

const PriceProduct = ({ offerPrice, price, original_price }) => {
	const currency = getCurrency()

	if (!offerPrice && !price) return <h4>No price</h4>

	return (<>
		{(!offerPrice || offerPrice === 0 ?
			<span className="product-price">
				{currency}{parseFloat(price)}
			</span>
			:
			<span className="product-price">
				<del>
					{currency}{parseFloat(original_price)}
				</del>
				{' '}
				{currency}{parseFloat(original_price)}
			</span>
		)}
	</>)
}

export default PriceProduct;