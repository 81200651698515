import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { getConfig } from '../../components/tools'
import StripeCheckForm from './StripeCheckForm'

const config = getConfig()

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise = null
if (config) {
	if (config.stripe_key) {
		stripePromise = loadStripe(config.stripe_key);
	}
}

export default function CardPayment({ secret }){
	// Validations
	if (!secret) return <>No secret</>
	
	const options = {
		// passing the client secret obtained from the server
		clientSecret: secret,
	}

	return(
		<Elements stripe={stripePromise} options={options}>
			<StripeCheckForm/>
		</Elements>
	)
}