import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Row, Col } from 'reactstrap';
import InformationProductCard from '../../components/ProductInformationComponentCard';
import InformationProductOnModal from '../../components/ModalDetailProduct';
import { api, NoData } from "../../components/tools";

function RatingProducts() {
	const [viewProduct, setViewProduct] = useState("");
	const [products, setProducts] = useState(false);
	const [category, setCategory] = useState({});
	const [action, setAction] = useState(false);

	const getProducts = () => {
		// Get local products
		let local_products = localStorage.getItem("_productsRating");
		if (local_products) {
			const _products = JSON.parse(local_products)

			setProducts(_products)

			return _products
		}

		// Get API products
		const limit_end = localStorage.getItem('items_home')
		api({
			order: 'i.promo_price',
			limit_end: limit_end,
			url: 'products',
			limit_start: 0,
		}).then((resp) => {
			console.log('RatingProducts', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get products: " + resp.data.error)
				}

				// JSON to array
				let result = [];
				for (let i in resp.data) result.push(resp.data[i])

				// Set products
				setProducts(result)

				// Save products on local
				localStorage.setItem("_productsRating", JSON.stringify(result));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get products: " + err)
		})
	}

	useEffect(() => {
		if (!products) getProducts()
	}, [products]);
	
	const quickView = () => {
		setAction(!action);
	}

	const toggle = () => setAction(!action);

	const onClickQuickView = (product) => {
		setAction(true);
		setViewProduct(product);
		setCategory(product.category);
	}

	return (<>
		{!products ? <NoData /> : <>
			<Row>
				<ToastContainer autoClose={900} />
				{products.map((product, i) => {
					if(!product) return <></>

					return (
						<Col key={'rating_'+i} xl={2} lg={3} md={4} xs={6}>
							<InformationProductCard
								onClickQuickView={onClickQuickView}
								product={product}
							/>
						</Col>
					)
				})}
			</Row>
			<InformationProductOnModal
				viewProduct={viewProduct}
				quickView={quickView}
				category={category}
				action={action}
				toggle={toggle}
			/>
		</>}
	</>);
}


export default React.memo(RatingProducts);