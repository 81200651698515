import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const Search = () => {
    const [word, setWord] = useState("");
    const navigate = useNavigate();

    const handleSearch = (e) => {
        e.preventDefault();
        if (word.trim() !== "") {
            navigate("/search/" + word.trim());
        }
    }

    const handleInputChange = (e) => {
        setWord(e.target.value);
    }

    return (
        <div className="py-md-3 py-2">
            <Container>
                <Row className="align-items-center">
                    <Col md={12} >
                        <div className="right-nav align-items-center d-flex justify-content-end">
                            <form className="form-inline border rounded w-100" onSubmit={handleSearch}>
                                <input
                                    className="form-control border-0 border-left col"
                                    placeholder="Buscar productos"
                                    aria-label="Search"
                                    id="searchProduct"
                                    type="search"
                                    value={word}
                                    onChange={handleInputChange} // Añadir el manejador onChange
                                />
                                <button 
                                    className="btn btn-primary col-auto"
                                    type="submit"
                                >
                                    <i className="las la-search" />
                                </button>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Search;
