import React, { useState, useEffect, useCallback } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import ListProducts from '../../widgets/shop/listproducts';
import HeaderPage from '../../layout/header/headerPage';
import Pagination from '../../components/Pagination';
import Footer from '../../layout/footer/footer';
import Search from '../../layout/header/search';

import { api, NoData, Loading } from "../../components/tools";

const ProductSearchByIdName = () => {
	const { search } = useParams();

	const [view, setView] = useState(localStorage.getItem("ProductLayout"));
	const ProductParPage = localStorage.getItem("totalPerPage");
	const [dataProducts, setDataProducts] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [startValue, setStartValue] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [count, setCount] = useState(0);
	const [word, setWord] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const changeView = (new_view) => {
		if (new_view !== view) {
			setView(new_view);

			localStorage.setItem("ProductLayout", new_view);
		}
	}

	const searchProducts = (search_text = '') => {
		console.log('searchProducts', search);
		if (search_text === '') search_text = search

		let options = {
			limit_end: ProductParPage,
			limit_start: startValue,
			url: 'products',
		}
		if (search === word) {
			// Get local products
			let local_products = localStorage.getItem("_searchProducts");
			if (local_products) {
				const _products = JSON.parse(local_products)

				setDataProducts(_products)

				return
			}
		} else {
			options.search = search_text
		}

		// Get API products
		api(options).then((resp) => {
			console.log('searchProducts', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get products: " + resp.data.error)
				}

				if (resp.data.length <= 0) {
					setDataProducts([])
					return
				}

				// JSON to array
				let result = [];
				for (let i in resp.data) result.push(resp.data[i])

				// Set products
                setWord(search_text);
				setDataProducts(result)
				localStorage.setItem("_searchProducts", JSON.stringify(result));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get products: " + err)
		})
	}

	const refetch = useCallback(() => {
		// Get API products
		api({
			search: search,
			url: 'countProducts',
		}).then((resp) => {
			console.log('countProducts', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get products: " + resp.data.error)
				}

				setTotalPages(Math.ceil(resp.data / ProductParPage));
				setCount(resp.data);
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error count products: " + err)
		})
	}, [ProductParPage, setTotalPages, setCount])

	useEffect(() => {
		if (search) {
			console.log('search', search);
			
			setCurrentPage(1);
			setStartValue(0);

			searchProducts(search)
			refetch()
		}
	}, [search]);

	const previousProduct = () => {
		if (startValue > 0) {
			localStorage.removeItem('_searchProducts')

			let result = startValue - ProductParPage;
			setStartValue(result);
		}
	}

	const NextProduct = () => {
		if (currentPage !== totalPages) {
			localStorage.removeItem('_searchProducts')

			let result = currentPage;
			setStartValue(ProductParPage * result);
		}
	}

	return (
		<>

			<HeaderPage />
			<div className="page-content">
				<section>
					<Search />
					<Container>
						<Row>
							<>
								<div className="col-lg-12 col-md-12">
									<Row>
										<Col md={12}>
											<div className="view-filter" style={{ textAlign: 'right' }}>
												{view === 'Gridview' ?
													<>
														<Link to="#" className="sky-grid-view active" onClick={() => changeView('Gridview')}>
															<i className="lab la-buromobelexperte" />
														</Link>
														<Link to="#" className="sky-list-view " onClick={() => changeView('Listing')} >
															<i className="las la-list" />
														</Link>
													</>
													:
													<>
														<Link to="#" className="sky-grid-view " onClick={() => changeView('Gridview')}>
															<i className="lab la-buromobelexperte" />
														</Link>
														<Link to="#" className="sky-list-view active" onClick={() => changeView('Listing')} >
															<i className="las la-list" />
														</Link>
													</>
												}
											</div>
										</Col>
									</Row>
									<Row>
										{!dataProducts ? <Loading /> :
											dataProducts.map((product, i) => (
												<ListProducts productData={product} key={i + "category"} countProduct={count} col_lg={3} layout={view} />
											))
										}
										<div className="text-center col-12">
											{(dataProducts.length > 0) ?
												<Pagination
													currentPage={currentPage}
													setCurrentPage={setCurrentPage}
													totalPages={totalPages}
													NextProduct={NextProduct}
													previousProduct={previousProduct}
												/>
												:
												<Row className="text-center12">
													<NoData />
												</Row>
											}
										</div>
									</Row>
								</div>
							</>
						</Row>
					</Container>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default React.memo(ProductSearchByIdName);