import React, { useState } from 'react';
import {ToastContainer } from 'react-toastify'; 
import InformationProductCard from '../../components/ProductInformationComponentCard';
import InformationProductList from '../../components/ProductInformationComponentList';
import InformationproductnModal from '../../components/ModalDetailProduct';
import GifLoaderComponent from '../../gifLoader/gifloaderComponent';

function ListProducts (props){
    const { productData, loading, layout } = props;

    const [viewProduct, setViewProduct] = useState("");
    const [category, setCategory] = useState({});
    const [action, setAction] = useState(false);

    const quickView =()=> {
       setAction(!action);
    }
    
    const toggle = () => setAction(!action);

    const onClickQuickView = (product) =>{
        setCategory(product.category);
        setViewProduct(product);
        setAction(true);
    }
  
    const ShowProducts = ()=>{
        return( <>
            {(layout ===  'Listing') ?
                <InformationProductList 
                    onClickQuickView={onClickQuickView} 
                    product={productData} 
                />             
                :
                <div className={`col-xl-3 col-lg-3 col-md-4 col-xs-6`} >
                    <InformationProductCard 
                        onClickQuickView={onClickQuickView} 
                        product={productData} 
                    />
                </div>
            }
        </> )
    }

    if(loading) return <GifLoaderComponent/>;
    
    return(
        <>
            <ToastContainer autoClose={900} />
            <ShowProducts />
            <InformationproductnModal 
                viewProduct={viewProduct} 
                quickView={quickView} 
                category={category} 
                action={action} 
                toggle={toggle} 
            />
        </>
    )
}

export default React.memo(ListProducts);