import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

import HelperForAddresses from './helperForAddresses';
import CustomerDataForm from './customerDataForm';
import ListSales from './listSales';
import classnames from 'classnames';

const MainCustomerData = ({ token }) => {
	const history = useNavigate()
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	const [activeTab, setActiveTab] = useState('1');
	const [client, setClient] = useState(null);

	const closeSession = () => {
		localStorage.removeItem("_client");
	}

	useEffect(() => {
		let client = localStorage.getItem("_client")
		if (client) {
			client = JSON.parse(client)

			setClient(client)
		}else{
			history('/login')
		}
	}, [])
	
	return (
		<>
			<HeaderPage />
			{!client ? <></> :
				<section>
					<div className="container-fluid">
						<div style={{ textAlign: 'right' }}>
							<Link onClick={() => closeSession()} to="/">
								<i className="las la-power-off"></i> Cerrar sesión
							</Link>
						</div>
						<Nav tabs>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === '1' })}
									style={{cursor: 'pointer'}}

									onClick={() => { toggle('1'); }}
								>
									Información
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === '2' })}
									style={{cursor: 'pointer'}}

									onClick={() => { toggle('2'); }}
								>
									Direcciones de envió
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={classnames({ active: activeTab === '3' })}
									style={{cursor: 'pointer'}}
									
									onClick={() => { toggle('3'); }}
								>
									Compras
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<Row>
									<Col sm="12">
										<CustomerDataForm />
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId="2">
								<HelperForAddresses />
							</TabPane>
							<TabPane tabId="3">
								<Row>
									<ListSales token={token} />
								</Row>
							</TabPane>
						</TabContent>
					</div>
				</section>
			}
			<Footer />
		</>
	);
}

export default React.memo(MainCustomerData);