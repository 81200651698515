import { useState } from "react";

import { Link, useNavigate } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

import { api, lan } from "../../components/tools";

const RecoverPassword = () => {
	const history = useNavigate()

	/* Hooks
	======================================== */
	const [showPass, setShowPass] = useState(false);
	const [validCode, setValidCode] = useState('');
	const [showCode, setShowCode] = useState('');
	const [code, setCode] = useState('');
	const [tel, setTel] = useState('');
	const [data, setData] = useState({
		pass2: '',
		pass: '',
	});

	/* Functions
	======================================== */
	const goLogin = () => {
		history("/login")
	}

	// Function that ejecute when the user write an input
	const updateData = (e) => {
		if (e.target.name) {
			let _data = data
			_data[e.target.name] = e.target.value

			setData(_data);
		}
	}

	const updateTel = e => {
		setTel(e.target.value);
	}

	// Validate phone number
	const isValid = (tel) => {
		var phoneRe = /^[0-9]{10}$/;
		var digits = tel.replace(/\D/g, "");
		return phoneRe.test(digits);
	}
	
	// Send SMS code
	const validate = (e) => {
		e.preventDefault()

		// Validations
		if (!isValid(tel)) {
			toast.error("Número de teléfono no valido");

			return;
		}

		api({
			url: 'clients/recovery',
			tel: tel,
		}).then((resp) => {
			// Update hooks
			setValidCode(resp.data.code)
			setShowCode(true)
			setData({
				...data,
				...resp.data
			})
		})
	}

	const validateCode = () =>{
		console.log('validateCode', validCode, code)

		if (validCode === parseInt(code)) setShowPass(true)
	}

	const updatePass = () =>{
		console.log('updatePass')

		// Validations
		let error = false
		let error_message = "Error"
		if (!data.pass || !data.pass2) {
			error = true
			error_message = "La contraseña es requerida"
		}
		if (data.pass !== data.pass2) {
			error = true
			error_message = "La contraseña deben ser iguales"
		}
		if (error) {
			toast.error(error_message);
			return;
		}

		api({
			url: 'clients',
			type: 'PUT',
			...data,
		}).then(() => {
			toast.success('Contraseña actualizada')

			goLogin();
		})
	}
	
	/* Components
	======================================== */
	return (
		<>
			<HeaderPage />
			<div className="page-content">
				<section>
					<Container>
						<Row className="justify-content-center">
							<div className="col-lg-5">
								<div className="shadow p-3">
									{showPass ? 
										<>
											<div className="form-group">
												<input
													data-error="Contraseña requerida"
													placeholder={lan('pass')}
													className="form-control"
													required="required"
													type="password"
													name="pass"

													onChange={(e) => updateData(e)}
												/><br />
												<input
													data-error="Contraseña requerida"
													placeholder={lan('repeat')}
													className="form-control"
													required="required"
													type="password"
													name="pass2"

													onChange={(e) => updateData(e)}
												/>
											</div>
											<button className="btn btn-primary btn-block" onClick={() => updatePass()}>
												{lan('update')}
											</button>
										</> 
										: <>
										{showCode ? 
											<>
												<div className="form-group">
													<label htmlFor="code">{lan('sms_send')}</label>
													<input
														data-error="Código de teléfono requerido"
														placeholder="12346"
														className="form-control"
														required="required"
														type="number"
														name="code"

														onChange={(e) => setCode(e.target.value) }
														value={code}
													/>
												</div>
												<button className="btn btn-primary btn-block" onClick={() => validateCode()}>
													{lan('validate')}
												</button>
											</> 
											: 
											<form id="contact-form" method="post" onSubmit={validate} style={{ paddingTop: 30 }}>
												<div className="form-group">
													<label htmlFor="code">{lan('phone')}</label>
													<input
														data-error="Numero de teléfono requerido"
														placeholder="0123456789"
														className="form-control"
														required="required"
														name="tel"
														type="tel"
														id="tel"

														onChange={updateTel}
														value={tel}
													/>
												</div>
												<button type="submit" className="btn btn-primary btn-block">
													{lan('recovery')}
												</button>
												<div className="d-flex align-items-center text-center justify-content-center mt-4">
													<Link to="/login">{lan('login')}</Link>
												</div>
											</form>
										}
									</>}
									<div className="d-flex align-items-center text-center justify-content-center mt-4">
										<span className=" mr-1">¿No tienes cuenta?</span>
										<Link to="/sign-up">Crear cuenta</Link>
									</div>
								</div>
							</div>
						</Row>
					</Container>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default RecoverPassword;