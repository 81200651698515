import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

import { api, NoData, getCurrency } from "../../components/tools";

const logo = require(`../../assets/images/default-image.png`).default

const Cart = () => {
	const [products, setProducts] = useState(null)

	const updatePrices = (items) => {
		console.log('updatePrices', items)

		// Get items ids
		let ids = [];
		items.map((value) => {
			ids.push(value.item_id)
		})

		// Get API products
		api({
			url: 'products',
			ids: ids,
		}).then((resp) => {
			console.log('products', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get products: " + resp.data.error)
				}

				// Update items price
				resp.data.map((value) => {
					items.map((v, i) => {
						if (v.item_id === value.item_id) items[i].price = value.price
					})
				})

				// Set products
				setProducts(items)

				// Update products on local
				localStorage.setItem("CartProduct", JSON.stringify(items));
				localStorage.removeItem("_update_prices")
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get products: " + err)
		})
	}

	const GetCartItems = () => {
		const items = JSON.parse(localStorage.getItem("CartProduct"))

		if (items) {
			if (items.length === 0) {
				localStorage.removeItem("CartProduct")

				return
			}

			const update_prices = JSON.parse(localStorage.getItem("_update_prices"))
			if (update_prices) {
				updatePrices(items)
			} else {
				setProducts(items)
			}
		}
	}

	const calculate_price_levels = (product, amount) => {
		let amount_to_evaluate = parseInt(amount);

		if (product.levels_price) {
			let levels_price = product.levels_price

			if (levels_price.length > 0) {
				let numMax = levels_price.length;
				for (let index = 0; index < levels_price.length; index++) {
					const element = levels_price[index];

					if (index < numMax) {
						let nextValue = levels_price[index + 1]

						if (nextValue !== undefined) {
							if (amount_to_evaluate >= parseInt(element.quantity) && amount_to_evaluate < nextValue.quantity) {
								return parseFloat(element.price);
							}
						} else {
							if (amount_to_evaluate >= parseInt(element.quantity)) {
								return parseFloat(element.price);
							}
						}
					}
				}

				return product.price;
			}
		}

		return product.price;
	}

	const RemoveItem = (Index) => {
		var CartValue = JSON.parse(localStorage.getItem("CartProduct"));
		CartValue = CartValue.slice(0, Index).concat(CartValue.slice(Index + 1, CartValue.length));
		localStorage.removeItem("CartProduct");
		localStorage.setItem("CartProduct", JSON.stringify(CartValue));

		GetCartItems()
	}

	const AddQty = (item, Index) => {
		var CartValue = JSON.parse(localStorage.getItem("CartProduct"));
		CartValue[Index].Qty = parseInt(CartValue[Index].Qty + 1);

		// Calculate price if price levels exist
		const level_price = calculate_price_levels(CartValue[Index], CartValue[Index].Qty);
		CartValue[Index].price = !level_price ? parseFloat(CartValue[Index].original_price) : level_price

		if (parseInt(CartValue[Index].Qty) > parseInt(item.stock)) {
			toast.error("Has alcanzado el límite de stock disponible");
			return;
		}

		localStorage.removeItem("CartProduct");
		localStorage.setItem("CartProduct", JSON.stringify(CartValue));

		GetCartItems()
	}

	const RemoveQty = (Index) => {
		var CartValue = JSON.parse(localStorage.getItem("CartProduct"));

		if (CartValue[Index].Qty !== 1) {
			CartValue[Index].Qty = parseInt(CartValue[Index].Qty - 1);

			// Calculate price if price levels exist
			const level_price = calculate_price_levels(CartValue[Index], CartValue[Index].Qty);
			CartValue[Index].price = !level_price ? parseFloat(CartValue[Index].original_price) : level_price

			if (level_price) CartValue[Index].price = level_price

			localStorage.removeItem("CartProduct");
			localStorage.setItem("CartProduct", JSON.stringify(CartValue));

			GetCartItems()
		} else {
			RemoveItem(Index);
		}
	}

	const imageError = (currentTarget, product) =>{
		if (product.images) {
			if (product.images.length > 0) {
				currentTarget.src = product.images[0]
			}
		}else{
			currentTarget.src = logo
		}
	}

	const calculateTaxes = () =>{
		let total_taxes = 0
		for (let index = 0; index < products.length; index++) {
			const product = products[index]

			if (product.tax) total_taxes += (product.tax * product.Qty)
		}

		return total_taxes
	}

	const calculateSubTotal = () =>{
		let total = 0
		for (let index = 0; index < products.length; index++) {
			const product = products[index]

			total += (product.price * product.Qty)
		}

		const taxes = calculateTaxes()

		return total - taxes
	}

	useEffect(() => {
		if (!products) GetCartItems()
	}, [products]);

	return (
		<>
			<HeaderPage />
			<div className="page-content">
				<section>
					<Container>
						{(!products) ?
							<Row>
								<Col md={12} className="text-center pb-11">
									<NoData
										text="Su carrito esta vació."
									/>
									<Link className="btn btn-primary mr-3 mt-3" to="/">Inicio</Link>
									<Link className="btn btn-primary mt-3" to="/category/todos/todas">Continuar comprando</Link>
								</Col>
							</Row>
							:
							<Row>
								<div className="col-lg-8">
									<div className="table-responsive">
										<table className="cart-table table">
											<thead>
												<tr>
													<th scope="col">Producto</th>
													<th scope="col">Precio</th>
													<th scope="col">Cantidad</th>
													<th scope="col">Total</th>
													<th scope="col"></th>
												</tr>
											</thead>
											<tbody>
												{products.map((CartItem, index) => {
													let name = CartItem.name

													// EC-961 Variation name
													if (CartItem.variation_name) name = CartItem.name+' - '+CartItem.variation_name

													return (<tr key={index}>
														<td>
															<div className="media align-items-center">
																<Link to="#">
																	<img 
																		style={{ height: '100px', width: '100px' }} 
																		src={`${CartItem.image}`} 
																		className="img-fluid" 
																		alt="..."

																		onError={({ currentTarget }) => {
																			imageError(currentTarget, CartItem)
																		}}
																	/>
																</Link>
																<div className="media-body ml-3">
																	<div className="product-title mb-2">
																		{name}
																	</div>
																</div>
															</div>
														</td>
														<td>
															{getCurrency()}{(Math.round(CartItem.price * 100) / 100).toFixed(2)}
														</td>
														<td>
															<div className="d-flex justify-content-center align-items-center">
																<Link to="#" className="btn btn-primary btn-product-up btn-sm" onClick={() => RemoveQty(index)}>
																	<i className="las la-minus" />
																</Link>
																<h5>
																	<Badge color="light">
																		{parseInt(CartItem.Qty)}
																	</Badge>
																</h5>
																<Link to="#" className="btn btn-primary btn-product-down btn-sm" onClick={() => AddQty(CartItem, index)}>
																	<i className="las la-plus" />
																</Link>
															</div>
														</td>
														<td>
															{getCurrency()}{(Math.round((CartItem.price * CartItem.Qty) * 100) / 100).toFixed(2)}
														</td>
														<td>
															<Link
																to="#"
																type="submit"
																className="btn btn-primary btn-sm"
																onClick={
																	() => RemoveItem(index)
																}
															>
																<i className="las la-times" />
															</Link>
														</td>
													</tr>)
												})}
											</tbody>
										</table>
									</div>
								</div>
								<div className="col-lg-4 pl-lg-5 mt-8 mt-lg-0">
									<div className="shadow rounded p-5">
										<h4 className="text-black text-center mb-2">Total del carrito</h4>
										<div className="d-flex justify-content-between align-items-center border-bottom py-3">
											<span>Subtotal</span>
											<span>{getCurrency()}{calculateSubTotal().toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
										</div>
										<div className="d-flex justify-content-between align-items-center border-bottom py-3">
											<span>Impuestos</span>
											<span>{getCurrency()}{calculateTaxes().toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</span>
										</div>
										<div className="d-flex justify-content-between align-items-center pt-3 mb-5">
											<span className=" h5">Total</span>
											<span className=" font-weight-bold h5">
												{getCurrency()}{
													products
														.reduce((fr, CartItem) => fr + (CartItem.Qty * parseFloat(CartItem.price)), 0)
														.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
												}
											</span>
										</div>
										<Link className="btn btn-primary btn-animated btn-block" to="/direction?checkout=1">
											Proceder a pago
										</Link>
									</div>
								</div>
							</Row>
						}
					</Container>
				</section>
			</div>
			<Footer />
		</>
	);

}

export default React.memo(Cart);