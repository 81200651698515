import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';
import { lan } from '../../components/tools';

const OrderComplete = () => {
	return (
		<>	
			<HeaderPage />
			<div className="page-content">
				<section className="text-center pb-11">
					<Container>
						<Row>
							<Col md={12}>
								<h3 className="mb-4">{lan('order_complete_message')}</h3>
								<Link className="btn btn-primary btn-animated" to="/customer">
									<i className="las la-list-alt mr-1" />
									{lan('my_purchases')}
								</Link> 
								<span>&nbsp;&nbsp;</span>
								<Link className="btn btn-dark btn-animated" to="/">
									<i className="las la-shopping-cart mr-1" />
									{lan('continue_shopping')}
								</Link>
							</Col>
						</Row>
					</Container>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default OrderComplete;