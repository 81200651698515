import React from 'react';
import { Table } from 'reactstrap';

import { getCurrency } from './tools';

const LevelsPrice = ({ levels_price, price = 0 }) => {
    if (!levels_price) return <></>
    if (levels_price.length < 1) return <></>

	const currency = getCurrency()
    let levels_html = []

	for (let index = 0; index < levels_price.length; index++) {
		const level = levels_price[index];
		let next_quantity = '+'
		if (levels_price[index + 1]) {
			const before_quantity = levels_price[index + 1].quantity - 1
			next_quantity = ' - '+before_quantity
		}

		const percent = ((price - level.price) * 100) / price
		
		levels_html.push(
			<tr key={"level_"+level.id}>
				<td>{level.quantity}{next_quantity}</td>
				<td>{percent}%</td>
				<td>{currency}{level.price}</td>
			</tr>
		)
	}

    return (<Table responsive>
			<thead>
				<tr>
					<th>Cantidad</th>
					<th>Descuento</th>
					<th>Precio</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>1 - {levels_price[0].quantity - 1}</td>
					<td>0%</td>
					<td>{currency}{price}</td>
				</tr>
				{levels_html}
			</tbody>
	</Table>);
}

export default LevelsPrice;