import React, { useState, useEffect } from 'react';
import { Row, Container } from 'reactstrap';
import OwlCarousel from 'react-owl-carousel';
import { api } from "../../components/tools";

window.fn = OwlCarousel;

const HeroSection = () => {
	/* Hooks
	======================================== */
	const [slider, setSlider] = useState(null)

	const getSlider = () => {
		// Get local slider
		let local_slider = localStorage.getItem("_slider");
		if (local_slider) {
			const _slider = JSON.parse(local_slider)

			setSlider(_slider)

			return _slider
		}

		// Get API slider
		api({
			url: 'slider',
		}).then((resp) => {
			console.log('slider', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get slider: " + resp.data.error)
				}

				// Set slider
				setSlider(resp.data)

				// Save slider on local
				localStorage.setItem("_slider", JSON.stringify(resp.data));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get slider: " + err)
		})
	}

	/* Observers
	======================================== */
	useEffect(() => {
		window.scrollTo(0, 0)

		if (!slider) getSlider()
	}, []);
	
	return (
		<section className="banner pos-r p-0">
			{!slider ? <></>:
				<OwlCarousel
					navText={["<span class='las la-arrow-left'><span></span></span>", "<span class='las la-arrow-right'><span></span></span>"]}
					className="banner-slider owl-carousel no-pb owl-2"
					autoplay={true}
					dots={false}
					loop="true"
					items={1}
					nav
				>
					{slider.map((value, key) => {
						if (!value.image) return(<></>)
						
						return(
							<div key={'slider_'+key} className="item bg-pos-rt" style={{ backgroundImage: `url(${value.image})` }}>
								<Container className="h-100">
									<Row className="h-100 align-items-center">
										<div className="col-lg-7 col-md-12 custom-py-1 position-relative z-index-1"></div>
									</Row>
								</Container>
							</div>
						)
					})}
				</OwlCarousel>
			}
		</section>
	);
}

export default HeroSection;