import axios from 'axios';

import config from '../env.json'

const GetToken = async (data = {}) => {
	data.domain = (window.location.hostname === 'localhost') ? 'test.ecommpos.com' : window.location.hostname
	data.key = config.API_KEY

	// Get user data from API
	return await axios.get(config.URL_API+'getCustomers', { params: data })
}

export default GetToken;