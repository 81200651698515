import React, { useState, useEffect } from 'react';
import Pagination from '../../../../components/Pagination';
import Pageheading from '../../../../widgets/pageheading';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { api, NoData, Loading } from "../../../../components/tools";
import ListProducts from '../../../../widgets/shop/listproducts';
import HeaderPage from '../../../../layout/header/headerPage';
import Footer from '../../../../layout/footer/footer';

function ProductsFilterCategory() {
	const [view, setView] = useState(localStorage.getItem("ProductLayout"));
	const ProductParPage = localStorage.getItem("totalPerPage");
	const [dataProducts, setDataProducts] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [idCategory, setIdCategory] = useState("");
	const [startValue, setStartValue] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const { category, nombre } = useParams();
	const [count, setCount] = useState(0);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const changeView = (new_view) => {
		if (new_view !== view) {
			setView(new_view);

			localStorage.setItem("ProductLayout", new_view);
		}
	}

	const ProductsByCategory = () => {
		let options = {
			limit_end: ProductParPage,
			limit_start: startValue,
			url: 'products',
		}
		let cache = JSON.stringify(options)

		if (category === 'todas') {
			// Get local products
			let local_products = localStorage.getItem("_allProducts");
			if (local_products) {
				const _products = JSON.parse(local_products)

				setDataProducts(_products)

				return
			}
		} else {
			options.category = category

			// Get local products
			cache = JSON.stringify(options)
			let local_products = localStorage.getItem("_products_"+cache);
			if (local_products) {
				const _products = JSON.parse(local_products)

				setDataProducts(_products)

				return
			}
		}

		// Get API products
		api(options).then((resp) => {
			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get products: " + resp.data.error)
				}

				if (resp.data.length <= 0) {
					setDataProducts([])
					return
				}

				// JSON to array
				let result = [];
				for (let i in resp.data) result.push(resp.data[i])

				// Set products
				setDataProducts(result)
				
				// Save products on local
				if (category === 'todas') {
					localStorage.setItem("_allProducts", JSON.stringify(result))
				}else{
					localStorage.setItem("_products_"+cache, JSON.stringify(result))
				}
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get products: " + err)
		})
	}

	const refetch = () => {
		// Get API products
		api({
			category: category === 'todas' ? null : category,
			url: 'countProducts',
		}).then((resp) => {
			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get products: " + resp.data.error)
				}

				setTotalPages(Math.ceil(resp.data / ProductParPage));
				setCount(resp.data);
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error count products: " + err)
		})
	}

	useEffect(() => {
		if (!dataProducts) {
			if (!isNaN(parseInt(category))) {
				setIdCategory(category);
				setCurrentPage(1);
				setStartValue(0);

				ProductsByCategory()
				refetch()
			}
		} else {
			if (!isNaN(parseInt(category))) {
				if (parseInt(category) !== parseInt(idCategory)) {
					setIdCategory(category);
					setCurrentPage(1);
					setStartValue(0);

					ProductsByCategory()
					refetch()
				}
			} else {
				if (category !== idCategory) {
					setIdCategory('todas');
					setCurrentPage(1);
					setStartValue(0);

					ProductsByCategory()
					refetch()
				}
			}
		}
	}, [category])

	useEffect(() => {
		// Scroll to top
		window.scrollTo(0, 0)

		// Get new products
		ProductsByCategory()
	}, [startValue])

	const previousProduct = () => {
		if (startValue > 0) {
			let result = startValue - ProductParPage;
			setStartValue(result);
		}
	}

	const NextProduct = () => {
		if (currentPage !== totalPages) {
			let result = currentPage;
			setStartValue(ProductParPage * result);
		}
	}

	return (
		<>
			<HeaderPage />
			<section className="page_information">
				<Pageheading foldername={"Compras"} title={nombre.charAt(0).toUpperCase() + nombre.slice(1)} />
			</section>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<div className="view-filter" style={{ textAlign: 'right' }}>
								{view === 'Gridview' || !view ?
									<>
										<Link to="#" className="sky-grid-view active" onClick={() => changeView('Gridview')}>
											<i className="lab la-buromobelexperte" />
										</Link>
										<Link to="#" className="sky-list-view " onClick={() => changeView('Listing')} >
											<i className="las la-list" />
										</Link>
									</>
									:
									<>
										<Link to="#" className="sky-grid-view " onClick={() => changeView('Gridview')}>
											<i className="lab la-buromobelexperte" />
										</Link>
										<Link to="#" className="sky-list-view active" onClick={() => changeView('Listing')} >
											<i className="las la-list" />
										</Link>
									</>
								}
							</div>
						</Col>
					</Row>
					<Row>
						{!dataProducts ? <Loading /> :
							dataProducts.map((product, i) => (
								<ListProducts productData={product} key={i + "category"} countProduct={count} col_lg={3} layout={view} />
							))
						}
						<div className="text-center col-12">
							{(dataProducts.length > 0) ?
								<Pagination
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									totalPages={totalPages}
									NextPage={NextProduct}
									PreviousPage={previousProduct}
								/>
								:
								<Row className="text-center12">
									<NoData />
								</Row>
							}
						</div>
					</Row>
				</Container>
			</section>
			<Footer />
		</>
	);
}

export default React.memo(ProductsFilterCategory);