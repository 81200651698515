import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { InputGroup, Button, Input } from 'reactstrap';

const ToAddShoppingCart = ({ product, show_button = false }) => {
	const [quantity, setQuantity] = useState(1)

	// Validations
	if (!product) return <></>

	const calculate_price_levels = (product, amount) => {
		let Cart = JSON.parse(localStorage.getItem("CartProduct"));
		if (Cart == null) {
			Cart = new Array(0);
		}

		let existingQuantity = 0;
		let productAdd = Cart.find(productCart => productCart.item_id === product.item_id);

		if (productAdd !== null && productAdd !== undefined) {
			existingQuantity = parseInt(productAdd.Qty);
		}

		let amount_to_evaluate = parseInt(amount) + existingQuantity;

		if (product.levels_price) {
			let levels_price = product.levels_price

			if (levels_price.length > 0) {
				let numMax = levels_price.length;
				for (let index = 0; index < levels_price.length; index++) {
					const element = levels_price[index];

					if (index < numMax) {

						let nextValue = levels_price[index + 1]

						if (nextValue !== undefined) {
							if (amount_to_evaluate >= parseInt(element.quantity) && amount_to_evaluate < nextValue.quantity && product.location_stock >= quantity) {
								return parseFloat(element.price);
							}
						} else {
							if (amount_to_evaluate >= parseInt(element.quantity) && product.location_stock >= quantity) {
								return parseFloat(element.price);
							}
						}
					}
				}

				return product.price;
			}
		}

		return product.price;
	}

	const updateQuantity = (element, product, CartValue) =>{
		const suma = parseInt(element.Qty) + parseInt(quantity)

		if (suma <= product.location_stock) {
			// Calculate price if price levels exist
			let level_price = calculate_price_levels(product, quantity);
			if (level_price) element.price = level_price

			element.Qty = parseInt(suma);

			console.log('cartValue', CartValue);
			

			localStorage.setItem("CartProduct", JSON.stringify(CartValue));

			toast.success("Producto agregado");

			setQuantity(1);
		} else {
			let qtyAgregar = parseInt(product.location_stock) - parseInt(element.Qty);
			if (qtyAgregar > 0) {
				// Calculate price if price levels exist
				let level_price = calculate_price_levels(product, quantity);
				if (level_price) element.price = level_price
				
				element.Qty = parseInt(element.Qty) + parseInt(qtyAgregar);

				localStorage.setItem("CartProduct", JSON.stringify(CartValue));

				toast.success("Solo se agregaron: " + qtyAgregar + "al carrito");

				setQuantity(1);
			} else {
				setQuantity(1);

				toast.warning("No hay stock disponible");
			}
		}
	}
	
	const addToCart = (product) => {
		console.log('addToCart', product);

		// Validations
		if(!product){
			console.error('No product', product);
			return
		}
		if (quantity <= 0) {
			toast.error("Cantidad no valida");
			setQuantity(1);
			return;
		}

		let Cart = JSON.parse(localStorage.getItem("CartProduct"));
		if (!Cart) Cart = new Array(0)
		
		// EC-961 Add multiple variations
		let productAdd = null
		if (product.variation_id) {
			productAdd = Cart.find(productCart => productCart.item_id === product.item_id && productCart.variation_id === product.variation_id)
		}else{
			productAdd = Cart.find(productCart => productCart.item_id === product.item_id)
		}

		if (!productAdd && product.location_stock > 0) {
			// Not enough stock
			if (quantity > product.location_stock) {
				// Calculate price if price levels exist
				let level_price = calculate_price_levels(product, quantity);
				if (level_price) product.price = level_price

				// Add product to cart
				Cart.push({
					...product,
					ProductImage: product.main_image_id,
					stock: product.location_stock,
					Qty: product.location_stock,
					ProductName: product.name,
					item_id: product.item_id,
				});

				// Save on local
				localStorage.setItem("CartProduct", JSON.stringify(Cart));

				toast.success("Producto agregado");

				setQuantity(1);
			} else {
				// Calculate price if price levels exist
				let level_price = calculate_price_levels(product, quantity);
				if (level_price) product.price = level_price

				// Add product to cart
				Cart.push({
					...product,
					ProductImage: product.main_image_id,
					stock: product.location_stock,
					ProductName: product.Nombre,
					item_id: product.item_id,
					Qty: quantity,
				})

				localStorage.setItem("CartProduct", JSON.stringify(Cart));

				toast.success("Producto agregado");

				setQuantity(1);
			}
		} else {
			let productAdd = Cart.find(productCart => productCart.item_id === product.item_id);

			if (productAdd) {
				let CartValue = JSON.parse(localStorage.getItem("CartProduct"));

				for (let index = 0; index < CartValue.length; index++) {
					const element = CartValue[index]

					if (product.variation_id) {
						if (element.variation_id === product.variation_id) {
							updateQuantity(element, product, CartValue)
						}
					}else{
						if (element.item_id === product.item_id) {
							updateQuantity(element, product, CartValue)
						}
					}
				}
			}
		}

		// EC-962 Show quantity when add to cart
		// Get local cart
		let items = localStorage.getItem("CartProduct")
		items = JSON.parse(items)

		// Calculate total
		let total_items = 0
		for (const key in items) {
			if (Object.prototype.hasOwnProperty.call(items, key)) {
				const item = items[key]

				total_items += item.Qty
			}
		}

		// Update HTML
		var e = document.getElementById('total_items');
		e.innerHTML = total_items
	}

	if (parseInt(product.location_stock) <= 0){
		return (
			<Button className="btn btn-primary btn-animated mr-sm-4 mb-3 mb-sm-0"  >
				<i className="las la-exclamation-triangle mr-1"></i> Agotado
			</Button>
		)
	}

	// EC-961 Hide add button on variation
	// EC-967 Error on show add button on variations
	if (product.variations && !show_button) return <></>

	return (
		<InputGroup style={{ width: "50%" }} >
			<Input name="quantity" type="number" className="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
			<Button onClick={() => addToCart(product)} className="btn btn-primary btn-animated mr-sm-4 mb-3 mb-sm-0" >
				<i className="las la-shopping-cart mr-1" />
			</Button>
		</InputGroup>
	)
}

export default React.memo(ToAddShoppingCart);
