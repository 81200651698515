import React, { useState, useEffect } from 'react';
import {Row,Col} from 'reactstrap';
import Direction from './addressesForm';
import AddressList from './addressList';
import { api, getClient } from "../../components/tools";

const HelperDirection = ({ checkOut }) => {
    const [title, setTitle] = useState("Direcciones");
    const [dataAddress, setDataAddress] = useState({});
    const [addresses, setAddresses] = useState(false);
    const [showList, setShowList] = useState(true);
    const [action, setAction] = useState("new");

    const AddNewDirection = () => {
        setTitle("Nueva dirección");
        setShowList(false);
        setAction("new");
        setDataAddress({
            interior_number: "",
            outdoor_number: "",
            address_name: "",
            postal_code: "",
            status: true,
            comment: "",
            country: "",
            suburb: "",
            street: "",
            state: "",
            town: "",
        });
    }

    const getAddresses = () => {
		// Get local addresses
		let local_addresses = localStorage.getItem("_addresses");
		if (local_addresses) {
			const _addresses = JSON.parse(local_addresses)

			setAddresses(_addresses)

			return _addresses
		}

        // Get client data
        const user = getClient()
		if (!user){
            window.location = '/login'
            return
        }

		// Get API addresses
		api({
            customer_id: user.person_id,
			url: 'address',
		}).then((resp) => {
			console.log('addresses', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get addresses: " + resp.data.error)
				}

				// JSON to array
				let result = [];
				for (let i in resp.data) result.push(resp.data[i])

				// Set addresses
				setAddresses(result)

				// Save addresses on local
				localStorage.setItem("_addresses", JSON.stringify(result));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get addresses: " + err)
		})
	}

	useEffect(() => {
		if (!addresses) getAddresses()
	}, [addresses]);

    const EditDirection = (data) => {
        setTitle("Editar dirección");
        setDataAddress(data);
        setAction("update");
        setShowList(false);
    }

    const CloseForm = () => {
        setTitle("Direcciones")
        setShowList(true)
        getAddresses()
    }

    return (
        <section>
            <div className="container-fluid">
                <Row className="justify-content-center text-center">
                    <Col lg={8} md={10}>
                        <div className="mb-2">
                            <h6 className="text-primary mb-1">{title}</h6>
                        </div>
                    </Col>
                </Row>
                <Row >
                    {addresses && showList === true ?
                        <AddressList
                            AddNewDirection={AddNewDirection}
                            EditDirection={EditDirection}
                            CloseForm={CloseForm}
                            checkOut={checkOut}
                        />
                        :
                        <Direction
                            dataAddress={dataAddress}
                            CloseForm={CloseForm}
                            action={action}
                        />
                    }
                </Row>
            </div>
        </section>
    );
}

export default React.memo(HelperDirection);