import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Pageheading from '../../widgets/pageheading';
import { useForm } from 'react-hook-form';

import HeaderPage from '../../layout/header/headerPage';
import Footer from '../../layout/footer/footer';

import { api } from "../../components/tools";
import env from '../../env.json'

function Contact() {
	/* Hooks
	======================================== */
	const [config, setConfig] = useState(null)

	const getConfig = () => {
		// Get local config
		let local_config = localStorage.getItem("_config");
		if (local_config) {
			const _config = JSON.parse(local_config)

			setConfig(_config)

			return _config
		}

		// Get API config
		api({
			url: 'config',
		}).then((resp) => {
			console.log('config', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get config: " + resp.data.error)
				}

				// Set config
				setConfig(resp.data)

				// Save config on local
				localStorage.setItem("_config", JSON.stringify(resp.data));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get config: " + err)
		})
	}

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const [mensaje, setMensaje] = useState("");

	const onSubmit = (data, e) => {
		// Validations
		if(!config.correo_administrativo && !config.correo_contacto){
			setMensaje("La tienda no cuenta con correo de contacto")

			return
		}

		// Build mail data
		data.message = 'Cliente: '+data.firstName+' '+data.name+'<br />Correo: '+data.email+'<br />Teléfono: '+data.phone+'<br />Mensaje: '+data.message+'<br />'
		data.tittle = 'Contacto '+data.firstName+' '+data.name
		data.to = config.correo_administrativo
		data.from_name = data.name
		if (config.correo_contacto) {
			data.mails = config.correo_contacto.split(',')
		}

		document.getElementById("btn_send").disabled = true;
		document.getElementById("btn_send").textContent = "Enviando...";

		// Send message by API
		api({
			...data,
			key: env.API_KEY,
			url: 'sendMail',
			type: 'post'
		}).then((resp) => {
			console.log('sendMail', resp);

			// Validations
			if (resp.data) {
				if (resp.data.error) {
					setMensaje("Ocurrió un error al enviar el correo.")

					return
				}
			}
			if (resp.status !== 200) {
				setMensaje("Ocurrió un error al enviar el correo.")

				return
			}

			e.target.reset()
			setMensaje("Hemos enviado tu correo, nos pondremos en contacto a la brevedad posible")

			document.getElementById("btn_send").disabled = false;
			document.getElementById("btn_send").textContent = "Enviar";
		}).catch((err) => {
			console.log('err', err);

			document.getElementById("btn_send").disabled = false;
			document.getElementById("btn_send").textContent = "Enviar";

			throw new Error("Error on send message: " + err)
		})
	}

	const Map = () =>{
		if (!config) return
		if (!config.lat || !config.lng) return

		return(
			<Container>
				<Row className="justify-content-center text-center mb-5">
					<div className="col-lg-8">
						<div>
							<h2 className="mb-0">Ubicación de nuestra tienda</h2>
						</div>
					</div>
				</Row>
				<Row>
					<Col>
						<div className="map" style={{ height: '500px' }}>
							<iframe 
								src={"https://maps.google.com/maps?q="+config.lat+","+config.lng+"&z=15&output=embed"} 
								className="w-100 h-100 border-0" 
								allowFullScreen 
								title="Tienda" 
							/>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
	

	/* Observers
	======================================== */
	// Get config data
	useEffect(() => {
		window.scrollTo(0, 0)

		if (!config) getConfig()
	}, [config])

	return (
		<>
			<div className="page-content">
				<HeaderPage />
				<section>
					<Container>
						<Row className="mb-5">
							<div className="col-lg-8">
								<div className="mb-5">
									<div className="text-primary mb-1"> {mensaje}</div>
								</div>
								<form id="contact-formTTT" className="row" onSubmit={handleSubmit(onSubmit)}>
									<div className="messages" />
									<div className="form-group col-md-6">
										<label>Nombre<span className="text-danger">*</span></label>
										<input type="text" className="form-control" placeholder="First Name"  {...register('firstName', { required: true, maxLength: 80 })} />
										{errors.firstName && <span><div className="help-block with-errors">El nombre es requerido</div></span>}
									</div>
									<div className="form-group col-md-6">
										<label>Apellido<span className="text-danger">*</span></label>
										<input type="text" className="form-control" placeholder="Name"  {...register('name', { required: true, maxLength: 80 })} />
										{errors.name && <span><div className="help-block with-errors"> EL apellido es requerido</div></span>}
									</div>
									<div className="form-group col-md-6">
										<label>Correo<span className="text-danger">*</span></label>
										<input type="text" className="form-control" placeholder="Email"  {...register('email', { required: true, maxLength: 80 })} />
										{errors.Email && <span><div className="help-block with-errors">El correo electrónico es requerido</div></span>}
									</div>
									<div className="form-group col-md-6">
										<label>Telefono <span className="text-danger">*</span></label>
										<input type="text" className="form-control" placeholder="phone"  {...register('phone', { required: true, maxLength: 80 })} />
										{errors.phone && <span><div className="help-block with-errors">El teléfono es requerido</div></span>}
									</div>
									<div className="form-group col-md-12">
										<label>Mensaje <span className="text-danger">*</span></label>
										<textarea type="text" className="form-control" placeholder="Message"  {...register('message', { required: true, maxLength: 80 })} />
										{errors.Message && <span><div className="help-block with-errors">El mensaje es requerido</div></span>}
									</div>
									<Col md={12} className="mt-4">
										<button className="btn btn-primary btn-animated" type="submit" id='btn_send'>Enviar</button>
									</Col>
								</form>
							</div>
							<Col lg={4} className="mt-6 mt-lg-0">
								{!config ? <></> :
									<div className="shadow-sm rounded p-5">
										{!config.direccion_contacto ? <></> :
											<div className="d-flex mb-3">
												<div className="mr-2"> <i className="las la-map ic-2x text-primary" />
												</div>
												<div>
													<h6 className="mb-1 ">Dirección</h6>
													<p className="mb-0 ">{config.direccion_contacto}</p>
												</div>
											</div>
										}
										{!config.correo_administrativo ? <></> :
											<div className="d-flex mb-3">
												<div className="mr-2"> <i className="las la-envelope ic-2x text-primary" />
												</div>
												<div>
													<h6 className="mb-1 ">Correo</h6>
													<a href={"mailto:"+config.correo_administrativo}> {config.correo_administrativo}</a>
												</div>
											</div>
										}
										{!config.telefono_contacto ? <></> :
											<div className="d-flex mb-3">
												<div className="mr-2"> <i className="las la-mobile ic-2x text-primary" />
												</div>
												<div>
													<h6 className="mb-1 ">Teléfono</h6>
													<a href={"tel:"+config.telefono_contacto}>{config.telefono_contacto}</a>
												</div>
											</div>
										}
										<ul className="list-inline">
											{!config.url_red_social_facebook ? <></> :
												<li className="list-inline-item">
													<a target="_blank" rel="noreferrer" className=" shadow-sm rounded p-2" href={config.url_red_social_facebook}>
														<i className="la la-facebook" />
													</a>
												</li>
											}
											{!config.url_red_social_whatsapp ? <></> :
												<li className="list-inline-item">
													<a target="_blank" rel="noreferrer" className=" shadow-sm rounded p-2" href={"https://wa.me/52"+config.url_red_social_whatsapp}>
														<i className="la la-whatsapp" />
													</a>
												</li>
											}
											{!config.url_red_social_youtube ? <></> :
												<li className="list-inline-item">
													<a target="_blank" rel="noreferrer" className=" shadow-sm rounded p-2" href={config.url_red_social_youtube}>
														<i className="la la-youtube" />
													</a>
												</li>
											}
											{!config.url_red_social_twitter ? <></> :
												<li className="list-inline-item">
													<a target="_blank" rel="noreferrer" className=" shadow-sm rounded p-2" href={config.url_red_social_twitter}>
														<i className="la la-twitter" />
													</a>
												</li>
											}
											{!config.url_red_social_instagram ? <></> :
												<li className="list-inline-item">
													<a target="_blank" rel="noreferrer" className=" shadow-sm rounded p-2" href={config.url_red_social_instagram}>
														<i className="la la-instagram" />
													</a>
												</li>
											}
										</ul>
									</div>
								}
							</Col>
						</Row>
					</Container>
				</section>
				{config ? <Map />: <></>}
				<Footer />
			</div>
		</>
	);
}

export default React.memo(Contact);