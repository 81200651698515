import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { api } from "../../components/tools";

function Footer() {
	const year = new Date().getFullYear()

	/* Hooks
	======================================== */
	const [config, setConfig] = useState(false)
	const [logo, setLogo] = useState(null)

	/* Functions
	======================================== */
	const setStyle = (config) =>{
		// Update colors if exists
		if(config.color_fondo_producto_ecommerce) document.documentElement.style.setProperty('--color_fondo_producto_ecommerce', config.color_fondo_producto_ecommerce);
		if(config.color_fondo_ecommerce) document.documentElement.style.setProperty('--color_fondo_ecommerce', config.color_fondo_ecommerce);
		if(config.color_letra_ecommerce) document.documentElement.style.setProperty('--color_letra_ecommerce', config.color_letra_ecommerce);
		if(config.color_footer_letter) document.documentElement.style.setProperty('--color_footer_letter', config.color_footer_letter);
		if(config.color_footer) document.documentElement.style.setProperty('--color_footer', config.color_footer);
		if(config.color_theme) document.documentElement.style.setProperty('--color_theme', config.color_theme);
	}

	const getConfig = () => {
		// Get local config
		let local_config = localStorage.getItem("_config");
		if (local_config) {
			const _config = JSON.parse(local_config)

			setConfig(_config)
			setStyle(_config)

			return _config
		}

		// Get API config
		api({
			url: 'config',
		}).then((resp) => {
			console.log('config', resp);

			if (resp.data) {
				// Show error
				if (resp.data.error) {
					throw new Error("Error get config: " + resp.data.error)
				}

				// Set config
				setConfig(resp.data)

				// Set style
				setStyle(resp.data)

				// Save config on local
				localStorage.setItem("_config", JSON.stringify(resp.data));
			}
		}).catch((err) => {
			console.log('err', err);

			throw new Error("Error get config: " + err)
		})
	}

	/* Observers
	======================================== */
	useEffect(() => {
		window.scrollTo(0, 0)

		if (!config) getConfig()
	}, [])

	useEffect(() => {
		if (config) {
			if (config.img_pest_sitio) {
				setLogo(config.img_pest_sitio)
			}
		}
	}, [config])

	return (
		<footer className="py-11">
			<Container>
				<Row>
					<div className="col-12 col-lg-3">
						<Link className="footer-logo h2" to="/">
							<img 
								className="img-fluid" 
								src={logo} 
								alt="" 
							/>
						</Link>
						<ul className="list-inline mb-0">
							{!config.url_red_social_facebook ? <></> :
								<li className="list-inline-item">
									<a target="_blank" rel="noreferrer" className="ic-2x" href={config.url_red_social_facebook}>
										<i className="la la-facebook" />
									</a>
								</li>
							}
							{!config.url_red_social_whatsapp ? <></> :
								<li className="list-inline-item">
									<a target="_blank" rel="noreferrer" className="ic-2x" href={"https://wa.me/52"+config.url_red_social_whatsapp}>
										<i className="la la-whatsapp" />
									</a>
								</li>
							}
							{!config.url_red_social_youtube ? <></> :
								<li className="list-inline-item">
									<a target="_blank" rel="noreferrer" className="ic-2x" href={config.url_red_social_youtube}>
										<i className="la la-youtube" />
									</a>
								</li>
							}
							{!config.url_red_social_twitter ? <></> :
								<li className="list-inline-item">
									<a target="_blank" rel="noreferrer" className="ic-2x" href={config.url_red_social_twitter}>
										<i className="la la-twitter" />
									</a>
								</li>
							}
							{!config.url_red_social_instagram ? <></> :
								<li className="list-inline-item">
									<a target="_blank" rel="noreferrer" className="ic-2x" href={config.url_red_social_instagram}>
										<i className="la la-instagram" />
									</a>
								</li>
							}
						</ul>
					</div>
					{!config.direccion_contacto ? <></> :
						<div className="col-12 col-lg-3 mt-6 mt-lg-0">
							<div className="mr-2">
								<i className="las la-map ic-2x text-primary" />
							</div>
							<div>
								<h6 className="mb-1">Dirección</h6>
								<p className="mb-0">{config.direccion_contacto}</p>
							</div>
						</div>
					}
					{!config.correo_administrativo ? <></> :
						<div className="col-12 col-lg-3 mt-6 mt-lg-0">
							<div className="mr-2">
								<i className="las la-envelope ic-2x text-primary" />
							</div>
							<div>
								<h6 className="mb-1">Email</h6>
								<p className="mb-0">{config.correo_administrativo}</p>
							</div>
						</div>
					}
					{!config.telefono_contacto ? <></> :
						<div className="col-12 col-lg-3 mt-6 mt-lg-0">
							<div className="mr-2">
								<i className="las la-mobile ic-2x text-primary" />
							</div>
							<div>
								<h6 className="mb-1">Teléfono</h6>
								<p className="mb-0">{config.telefono_contacto}</p>
							</div>
						</div>
					}
				</Row>
				<hr className="my-8" />
				<Row className=" align-items-center">
					<Col md={7}>Copyright © {year} - Todos los derechos reservados <i className="las la-globe text-primary heartBeat2"></i>  <u><a className="text-primary" href="https://ecommpos.com" target="_blank" rel="noopener noreferrer">ecommpos.com</a></u>
					</Col>
					<Col md={5} className="text-md-right mt-3 mt-md-0">
						<ul className="list-inline mb-0">
							<li className="list-inline-item">
								<Link to="/">
									<img className="img-fluid" src={require(`../../assets/images/pay-icon/01.png`).default} alt="" />
								</Link>
							</li>
							<li className="list-inline-item">
								<Link to="/">
									<img className="img-fluid" src={require(`../../assets/images/pay-icon/02.png`).default} alt="" />
								</Link>
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;